import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Button, InputLabel, List, ListItemText, Tab } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import { Navigate, useNavigate } from "react-router";
import { formStyles } from "../../Styles/Formstyleclone";
import { API } from "../../config";
import Buttons from "../../Components/Shared/Buttons";
import Input from "../../Components/Shared/Input";
import { toast } from "react-toastify";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useFormik } from "formik";
import * as Yup from "yup";
import Api from "../../helper/api";
import ShowReport from "./ShowReport";
import dayjs from 'dayjs';


import Selects, { components, MenuListProps } from 'react-select';
import { gender, labSuffix, paymentMode, sampleFrom, sampleStatus, sampleType, sendRepoBy } from "../../helper/data";
import { useDispatch } from "react-redux";
import { setActivePatient, setValues } from "../../redux/findPatientSlice";

const ITEM_HEIGHT = 65;
const ITEM_PADDING_TOP = 8;

const PatientInformationForm = () => {
  const api = new Api();
  const navigate = useNavigate();
  const classes = formStyles();
  const dispatch = useDispatch();

  const TOKEN = localStorage.getItem("logintoken");

  // page states-------------------------------------
  // ------------------------------------------------
  const [submit, setSubmit] = useState(false);
  const [value, setValue] = useState("tab1");
  const [tabToMove, setTabToMove] = useState(null);
  const [rows, setRows] = useState([]);
  const [account, setAccount] = useState(); // lab accounts
  const [doctor, setDoctor] = useState([]);
  const [operators, setOperator] = useState([]); // users/operators
  const [investigation, setInvestigation] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [showReport, setShowReport] = useState({
    show: false,
    src: ""
  })

  const [sampleCollection, setSampleCollection] = useState([{
    sampleType: "",
    sampleStatus: "",
    barCodeId: "",
    date: new Date()
  }])

  const [payment, setPayment] = useState({
    bills: [
      {
        totalAmount: 0,
        discount: 0,
        netAmount: 0,
        discountBy: "",
        discountReason: "",
        dueAmount: 0
      }
    ],
    deposits: [
      {
        mode: "cash",
        paidAmount: 0,
      },
    ],
    freeOfCost: false
  })

  useEffect(() => {
    fetchTest();
    fetchAccount();
    fetchDoctor();
    fetchUser();
    fetchPriceList();
  }, []);

  // fetch lab accounts -----------------------------
  // ------------------------------------------------
  const fetchAccount = async () => {
    const data = await axios.get(`${API}/getaccountdetails`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setAccount(data?.data?.accountDetails);
  };


  // fetch doctors --------------------------------
  // ----------------------------------------------
  const fetchDoctor = async () => {
    const data = await axios.get(`${API}/getdoctorlist`, {
      headers: { authtoken: `${TOKEN}` },
    });
    setDoctor(data?.data?.doctors);
  };


  // fetch user/operators ---------------------------
  // ------------------------------------------------
  const fetchUser = async () => {
    try {
      const data = await api.fetchUser();

      setOperator(data.data.users)
    } catch (err) {
      console.log(err)
    }
  }


  // fetch pricelist ----------------------------------
  // --------------------------------------------------
  const fetchPriceList = async () => {
    try {
      const { data, status } = await api.fetchPriceList({}, {
        page: 1,
        quantity: 100000
      })

      if (status === 200) {
        setPriceList(data?.priceList);
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get report group ----------------------------------
  // ---------------------------------------------------
  const fetchTest = async (e) => {
    try {
      const data = await axios.get(`${API}/gettestcategory`, {
        headers: { authtoken: `${TOKEN}` },
      });
      setRows(data.data.testCategory);
    } catch (e) {
      console.log(e);
    }
  };


  // handle sample collection data----------------
  // ---------------------------------------------
  const handleSampleCollection = (name, value) => {
    setSampleCollection((prev) => {
      return [{ ...prev[0], [name]: value }]
    })
  }


  // handle payment data -------------------------
  // ---------------------------------------------
  const handlePayment = (type, name, value) => {
    if (type === "bill") {
      setPayment((prev) => {
        let clone = { ...prev };
        clone.bills[0][name] = value
        return { ...clone }
      })
    } else if (type === "deposit") {
      setPayment((prev) => {
        let clone = { ...prev };
        clone.deposits[0][name] = value
        return { ...clone }
      })
    } else if (type === "freeOfCost") {
      setPayment((prev) => {
        return { ...prev, [name]: value }
      })
    }
  }

  // handle investigation data ------------------------
  // --------------------------------------------------
  const handleInvestigation = (value) => {
    setInvestigation(value);
    let totalAmount = 0;

    for (let i = 0; i < value.length; i++) {
      totalAmount += value[i]?.rate
    }

    // set total amount
    setPayment((prev) => {
      let clone = { ...prev };
      clone.bills[0].totalAmount = totalAmount
      return { ...clone }
    })
  };


  // remove investigation ----------------------------
  // -------------------------------------------------
  const removeInvestigation = (index) => {
    const clonedInvestigation = [...investigation]

    clonedInvestigation.splice(index, 1)
    setInvestigation(clonedInvestigation);

    let totalAmount = 0;

    for (let i = 0; i < clonedInvestigation.length; i++) {
      totalAmount += clonedInvestigation[i]?.rate
    }

    // set total amount
    setPayment((prev) => {
      let clone = { ...prev };
      clone.bills[0].totalAmount = totalAmount
      return { ...clone }
    })
  };


  // handle due amount & netamount -------------------------
  // -------------------------------------------------------
  useEffect(() => {
    if (!payment.freeOfCost) {
      // calculate net amount
      const calculatedNetAmount = payment.bills[0].totalAmount - payment.bills[0].discount;

      setPayment((prev) => {
        let clone = { ...prev };
        clone.bills[0].netAmount = calculatedNetAmount
        return { ...clone }
      })


      // calculate due amount
      const calculatedDueAmount = calculatedNetAmount - payment.deposits[0].paidAmount;
      setPayment((prev) => {
        let clone = { ...prev };
        clone.bills[0].dueAmount = calculatedDueAmount
        return { ...clone }
      })
    }
  }, [payment.bills[0].totalAmount, payment.bills[0].discount, payment.deposits[0].paidAmount])


  const label = { inputProps: { "aria-label": "Checkbox demo" } };


  const validationSchema = Yup.object().shape({
    patientInfo: Yup.object().shape({
      labPrefix: Yup.string().required("Lab Account Prefix is required"),
      labSuffix: Yup.string().required("Lab suffix is required"),
      firstName: Yup.string().required("FirstName is required"),
      patientId: Yup.string(),
      gender: Yup.string().required("Gender is required"),
      age: Yup.string().required("Age is required"),
    })
  });

  const formik = useFormik({
    initialValues: {
      patientInfo: {
        labPrefix: "",
        labNumber: "",
        labSuffix: 25,
        firstName: "",
        lastName: "",
        patientId: "",
        gender: "",
        age: "",
        month: "",
        day: "",
        refferedBy: "",
        sampleFrom: "",
        sendRepBy: "",
        address: "",
        city: "",
        refID: "",
        phone: "",
        email: "",
        refSlipImg: [

        ],
        assignToUser: "",
        notes: "",
        urgentRequired: false
      },
      category: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      goToInvestigation(tabToMove)
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    isSubmitting,
    isValid,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleSubmit
  } = formik;

  useEffect(() => {
    if (submit && !isSubmitting && !isValid) {
      toast.error("All fields are required!")
      window.scrollTo(0, 0);
    }
  }, [isSubmitting, isValid])

  // registerPatient ------------------------
  // ----------------------------------------
  const registerPatience = async () => {
    try {
      const { data, status } = await api.registerPatient({
        ...values.patientInfo,
        category: values.category,
        investigation,
        sampleCollection,
        payment
      })

      if (status === 200) {
        toast.success("Patient Registred SuccessFully")

        dispatch(setActivePatient({
          patient: data.patient._id,
          sampleNo: data.patient.sampleNo
        }))

        dispatch(setValues({ name: "barCode", value: true }))

        setTimeout(() => {
          navigate('/list-patient')
        }, 2000);
      }

    } catch (err) {
      console.log(err);
      toast.error("Error in Registration")
    }
  };


  // go to investigation =--------------------
  // -----------------------------------------
  const goToInvestigation = async (tab) => {
    try {
      const { patientInfo } = errors

      if (patientInfo && Object.keys(patientInfo).length > 0) {
        toast.error("All fields are required!")
      } else {
        setValue(tab)
        window.scrollTo(0, 0);
      }
    } catch (err) {
      toast.error("All fields are required!")
    }
  }

  // handle tab switch -----------------------------
  // -----------------------------------------------
  const handleChange1 = (event, newValue) => {
    if (newValue === "tab2" || "tab3") {
      setTabToMove(newValue)
      setSubmit(true)
      handleSubmit()
    } else {
      setValue(newValue);
    }
  };

  // upload img --------------------------------
  // -------------------------------------------
  const uploadImg = async (e) => {
    if (e.target.files[0].type === "application/pdf") {
      alert("Please upload Images")
    } else {
      try {
        const { data, status } = await api.uploadSlip({
          image: e.target.files[0]
        }, { id: "" })

        if (status === 200) {
          if (data.length > 0) {
            const clone = [...values.patientInfo.refSlipImg]

            let _id = clone.length + 1
            clone.push({
              _id,
              src: data[0].src,
            })
            setFieldValue("patientInfo.refSlipImg", clone)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  }


  return (
    <div className={classes.root}>
      {showReport.show ? <ShowReport handleClose={() => setShowReport({ show: false, src: "" })} showReport={showReport} /> : ""}
      <div className={classes.collectorForm}>
        <div className={classes.formheader}
          style={{
            justifyContent: 'center',
          }}>
          <div className={classes.formname}>
            <div className={classes.formh2}
            >Add new patient registration</div>
            <div className={classes.formspecification}>
              Complete all 3 steps
            </div>
          </div>
          {/*  <div>
            <Buttons className={classes.formButton} onClick={()=>navigate('/list-patience')}>
              &nbsp; Back to  table
            </Buttons>
          </div> */}
        </div>

        <div>
          <Box sx={{ padding: "0 6%", borderColor: "blue" }}>
            <TabContext value={value} className={classes.tabs}>
              <Box sx={{ padding: "0 10%", marginBottom: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <TabList
                  onChange={handleChange1}
                  aria-label="lab API tabs example"
                  sx={{
                    padding: "0 10%",
                    "& .MuiTabs-indicator": { backgroundColor: "black" },
                  }}
                >
                  <Tab
                    label="1 Patient Information"
                    className={classes.tablist}
                    value="tab1"
                  />
                  <Tab
                    label="2 Investigations"
                    className={classes.tablist}
                    value="tab2"
                  />
                  <Tab label="3 Payments" className={classes.tablist} value="tab3" />
                </TabList>
              </Box>
              <Box sx={{ marginBottom: "40px" }}>
                <TabPanel value="tab1">
                  <div className={classes.formMain}>
                    <FormControl style={{ width: "100%" }}>
                      <div className={classes.formHeading}>
                        Basic information
                      </div>
                      <div style={{ width: "100%", padding: "0 2%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
                        <div>
                          <div className={classes.formLable}>Lab Account Pre*</div>
                          <FormControl style={{ width: "95%" }}>

                            <Selects
                              options={account?.map((account) => {
                                return {
                                  value: account?._id,
                                  label: account?.prefix
                                }
                              })}
                              value={
                                values.patientInfo?.labPrefix ?
                                  {
                                    value: account?.find((account) => account._id === values.patientInfo?.labPrefix)?._id,
                                    label: account?.find((account) => account._id === values.patientInfo?.labPrefix)?.prefix
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.labPrefix")}
                              name="patientInfo.labPrefix"
                              onChange={(value) => setFieldValue("patientInfo.labPrefix", value.value)}
                            />

                            {errors.patientInfo?.labPrefix && touched.patientInfo?.labPrefix && (
                              <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.labPrefix}</div>
                            )}
                          </FormControl>{" "}
                        </div>

                        <div>
                          <div className={classes.formLable}>Lab Suf*</div>
                          <FormControl style={{ width: "95%" }}>

                            <Selects
                              options={labSuffix}
                              value={
                                values.patientInfo?.labSuffix ?
                                  {
                                    value: labSuffix?.find((labSuffix) => labSuffix.value == values.patientInfo?.labSuffix)?.value,
                                    label: labSuffix?.find((labSuffix) => labSuffix.value == values.patientInfo?.labSuffix)?.label
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.labSuffix")}
                              onChange={(value) => setFieldValue("patientInfo.labSuffix", value.value)}
                            />
                          </FormControl>{" "}
                          {errors.patientInfo?.labSuffix && touched.patientInfo?.labSuffix && (
                            <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.labSuffix}</div>
                          )}
                        </div>

                        <div>
                          <div className={classes.formLable}>
                            Patient First Name*
                          </div>
                          <Input
                            type="text"
                            name="patientInfo.firstName"
                            placeholder="Enter First Name  here"
                            className={classes.formInput2}
                            value={values.patientInfo?.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                          {errors.patientInfo?.firstName && touched.patientInfo?.firstName && (
                            <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.firstName}</div>
                          )}
                        </div>

                        <div>
                          <div className={classes.formLable}>Last name</div>
                          <Input
                            type="text"
                            placeholder="Enter Last name here"
                            className={classes.formInput2}
                            value={values.patientInfo.lastName}
                            name="patientInfo.lastName"
                            onChange={handleChange}
                          />{" "}
                          <br />
                        </div>

                        {/* <div>
                          <div className={classes.formLable}>
                            Patient ID
                          </div>
                          <FormControl style={{ width: "100%" }}>
                            <Select
                              className={classes.selectInput2}
                              value={values.patientInfo.patientId}
                              MenuProps={{ getContentAnchorEl: () => null, ...MenuProps }}
                              name="patientInfo.patientId"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="">
                                <p>Select</p>
                              </MenuItem>
                              <MenuItem value="email">Email</MenuItem>
                              <MenuItem value="phone">Phone</MenuItem>
                            </Select>
                          </FormControl>{" "}
                          {errors.patientInfo?.patientId && touched.patientInfo?.patientId && (
                            <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.patientId}</div>
                          )}
                        </div> */}

                        <div>
                          <div className={classes.formLable}>Gender*</div>
                          <FormControl style={{ width: "95%" }}>

                            <Selects
                              options={gender}
                              value={
                                values.patientInfo?.gender ?
                                  {
                                    value: gender?.find((gender) => gender.value == values.patientInfo?.gender)?.value,
                                    label: gender?.find((gender) => gender.value == values.patientInfo?.gender)?.label
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.gender")}
                              onChange={(value) => setFieldValue("patientInfo.gender", value.value)}
                            />
                          </FormControl>{" "}
                          {errors.patientInfo?.gender && touched.patientInfo?.gender && (
                            <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.gender}</div>
                          )}
                        </div>

                        <div>
                          <div className={classes.formLable}>Age*</div>
                          <div style={{ display: "flex" }}>
                            <Input
                              type="number"
                              placeholder="Year"
                              className={classes.formInput2}
                              value={values.patientInfo.age}
                              name="patientInfo.age"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{ margin: "4px 4px 28px 0px" }}
                            />
                            <Input
                              type="number"
                              placeholder="Month"
                              className={classes.formInput2}
                              value={values.patientInfo.month}
                              name="patientInfo.month"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{ margin: "4px 4px 28px 0px" }}
                            />
                            <Input
                              type="number"
                              placeholder="Day"
                              className={classes.formInput2}
                              value={values.patientInfo.day}
                              name="patientInfo.day"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{ margin: "4px 4px 28px 0px" }}
                            />
                          </div>
                          {errors.patientInfo?.age && touched.patientInfo?.age && (
                            <div style={{ color: "red", fontSize: '13px' }}>{errors.patientInfo.age}</div>
                          )}
                        </div>

                        <div>
                          <div className={classes.formLable}>Reffered by</div>
                          <FormControl style={{ width: "95%" }}>

                            <Selects
                              options={[
                                {
                                  value: 1,
                                  label: "Self"
                                },
                                ...doctor?.map((doctor) => {
                                  return {
                                    value: doctor?._id,
                                    label: doctor?.name
                                  }
                                })
                              ]}
                              value={
                                values.patientInfo?.refferedBy === 1 ? { value: 1, label: "Self" }
                                  : values.patientInfo?.refferedBy ? {
                                    value: doctor?.find((doctor) => doctor._id === values.patientInfo?.refferedBy)?._id,
                                    label: doctor?.find((doctor) => doctor._id === values.patientInfo?.refferedBy)?.name
                                  } : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.refferedBy")}
                              onChange={(value) => setFieldValue("patientInfo.refferedBy", value.value)}
                            />
                          </FormControl>{" "}
                          <br />
                        </div>
                        <div>
                          <div className={classes.formLable}>Sample from</div>
                          <FormControl style={{ width: "95%" }}>
                            <Selects
                              options={sampleFrom}
                              value={
                                values.patientInfo?.sampleFrom ?
                                  {
                                    value: sampleFrom?.find((sampleFrom) => sampleFrom.value == values.patientInfo?.sampleFrom)?.value,
                                    label: sampleFrom?.find((sampleFrom) => sampleFrom.value == values.patientInfo?.sampleFrom)?.label
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.sampleFrom")}
                              onChange={(value) => setFieldValue("patientInfo.sampleFrom", value.value)}
                            />
                          </FormControl>{" "}
                        </div>

                        <div>
                          <div className={classes.formLable}> Send Repo by</div>
                          <FormControl style={{ width: "95%" }}>
                            <Selects
                              options={sendRepoBy}
                              value={
                                values.patientInfo?.sendRepBy ?
                                  {
                                    value: sendRepoBy?.find((sendRepoBy) => sendRepoBy.value == values.patientInfo?.sendRepBy)?.value,
                                    label: sendRepoBy?.find((sendRepoBy) => sendRepoBy.value == values.patientInfo?.sendRepBy)?.label
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.sendRepBy")}
                              onChange={(value) => setFieldValue("patientInfo.sendRepBy", value.value)}
                            />
                          </FormControl>{" "}
                          <br />
                        </div>



                      </div>
                      <div className={classes.formDiv1}>
                        <div
                          style={{ width: "62%", marginLeft: "2%" }}
                          className={classes.formDiv3}
                        >
                          <div className={classes.formLable}>Address</div>
                          <Input
                            type="text"
                            placeholder="Enter here"
                            className={classes.formInput3}
                            value={values.patientInfo.address}
                            onChange={handleChange}
                            name="patientInfo.address"
                          />{" "}
                          <br />
                        </div>
                        <div
                          style={{ width: "32%", marginLeft: "2%" }}
                          className={classes.formDiv3}
                        >
                          <div className={classes.formLable}>City</div>
                          <Input
                            type="text"
                            placeholder="Enter City here"
                            className={classes.formInput2}
                            value={values.patientInfo.city}
                            name="patientInfo.city"
                            onChange={handleChange}
                          />{" "}
                          <br />
                        </div>
                      </div>

                      <div style={{ width: "100%", padding: "0 2%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
                        <div >
                          <div className={classes.formLable}>
                            Reference ID
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter here"
                            className={classes.formInput2}
                            value={values.patientInfo.refID}
                            onChange={handleChange}
                            name="patientInfo.refID"
                          />{" "}
                          <br />
                        </div>
                        <div >
                          <div className={classes.formLable}>
                            Mobile Number
                          </div>
                          <Input
                            type="number"
                            placeholder="Enter mobile here"
                            className={classes.formInput2}
                            value={values.patientInfo.phone}
                            onChange={handleChange}
                            name="patientInfo.phone"
                          />{" "}
                          <br />
                        </div>
                        <div>
                          <div className={classes.formLable}>Email</div>
                          <Input
                            type="email"
                            placeholder="Enter email here"
                            className={classes.formInput2}
                            value={values.patientInfo.email}
                            onChange={handleChange}
                            name="patientInfo.email"
                          />{" "}
                          <br />
                        </div>
                      </div>

                      <div style={{ width: "100%", padding: "0 2%", display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
                        <div
                        >
                          <div className={classes.formLable}>
                            Reference slip image
                          </div>
                          <div
                            className={classes.refslip}
                            style={{
                              minWidth: "190px",
                              height: "98px",
                              marginTop: "9px",
                              padding: "10px 20px"
                            }}
                          >
                            {values.patientInfo.refSlipImg.length > 0 ? (
                              <div style={{ display: "flex", gridGap: "15px" }}>
                                {
                                  values.patientInfo.refSlipImg.map((slip, i) => {
                                    return (
                                      slip.src &&
                                      <div style={{ position: 'relative', }}>
                                        <img
                                          src={slip.src}
                                          style={{
                                            width: "70px",
                                            height: "80px",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div className={classes.vector} onClick={() => setShowReport({ show: true, src: slip.src })}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              d="M0.00200907 14.9975L0 24H9.00347L6.09413 21.091L12.0917 15.0937L8.90707 11.909L2.90951 17.9063L0.00200907 14.9975ZM14.9996 0.000457341L17.906 2.90654L11.9056 8.90671L15.0902 12.091L21.0907 6.0908L24 9.00026V0L14.9996 0.000457341Z"
                                              fill="#666666"
                                            />
                                            <path
                                              d="M9.00254 0.00200877L1.90735e-06 -4.80825e-07L1.04868e-06 9.00347L2.909 6.09413L8.90631 12.0917L12.091 8.90707L6.09366 2.90951L9.00254 0.00200877ZM23.9995 14.9996L21.0935 17.906L15.0933 11.9056L11.909 15.0902L17.9092 21.0907L14.9997 24L24 24L23.9995 14.9996Z"
                                              fill="#666666"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                <div style={{ width: '230px !important', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <Buttons component="label" onClick={() => { }} className={classes.filterButton2}>Upload Image
                                    <input type="file" name="uploadImg" style={{ display: "none" }} onChange={uploadImg} id="uploadImg" />
                                  </Buttons>
                                </div>

                              </div>
                            ) : (
                              <div style={{ width: '230px !important', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Buttons component="label" onClick={() => { }} className={classes.filterButton2}>Upload Image
                                  <input type="file" name="uploadImg" style={{ display: "none" }} onChange={uploadImg} id="uploadImg" />
                                </Buttons>
                              </div>

                            )}
                          </div>
                          {" "}
                          <br />
                        </div>
                        <div
                        >
                          <div className={classes.formLable}>
                            Assign to operator to verify
                          </div>
                          <FormControl style={{ width: "100%" }}>

                            <Selects
                              options={operators?.map((operator) => {
                                return {
                                  value: operator?._id,
                                  label: operator?.name
                                }
                              })}
                              value={
                                values.patientInfo?.assignToUser ?
                                  {
                                    value: operators?.find((operator) => operator._id === values.patientInfo?.assignToUser)?._id,
                                    label: operators?.find((operator) => operator._id === values.patientInfo?.assignToUser)?.name
                                  }
                                  : ""
                              }
                              styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                              onBlur={() => setFieldTouched("patientInfo.assignToUser")}
                              onChange={(value) => setFieldValue("patientInfo.assignToUser", value.value)}
                            />
                          </FormControl>{" "}
                          <br />
                        </div>
                        <div
                          style={{ width: "30%", marginLeft: "2%" }}
                          className={classes.formDiv3}
                        >
                        </div>
                      </div>

                      <div style={{ width: "100%", padding: "0 2%" }}>
                        <div
                        >
                          <div className={classes.formLable}>Notes</div>
                          <Input
                            type="text"
                            placeholder="Enter Notes here"
                            className={classes.formInput4}
                            value={values.patientInfo.notes}
                            onChange={handleChange}
                            name="patientInfo.notes"
                          />{" "}
                          <br />
                        </div>
                      </div>


                      <div className={classes.formLable}>
                        <Checkbox {...label}
                          color="default"
                          onChange={handleChange}
                          name="patientInfo.urgentRequired"
                          value={values.patientInfo.urgentRequired}
                          style={{
                            marginLeft: '12px',
                          }}
                        />
                        Urgent requirement
                      </div>{" "}
                      <div className={classes.formDiv4}
                        style={{ marginRight: '8%' }}
                      >
                        <Buttons
                          className={classes.cancelButton}
                          onClick={() => navigate("/list-patient")}
                        >
                          Cancel
                        </Buttons>
                        <Buttons
                          onClick={() => {
                            setTabToMove("tab2")
                            setSubmit(true)
                            handleSubmit()
                          }}
                          className={classes.submitButton}
                        >
                          Next Step
                        </Buttons>
                      </div>
                    </FormControl>
                  </div>
                </TabPanel>

                <TabPanel value="tab2">
                  <div>
                    <div className={classes.formMain} style={{ width: '95%', marginLeft: '4%' }}>
                      <FormControl style={{ width: '100%' }}>
                        <div className={classes.formDiv1}>
                          <div className={classes.formDiv2} style={{ width: "50%" }}>
                            <div className={classes.formHeading}>
                              Test information{" "}
                            </div>
                            <div className={classes.formLable}>
                              Select Category
                            </div>
                            <FormControl style={{ width: "95%" }}>
                              <Selects
                                options={[{ value: "", label: "All" }, ...rows?.map((row) => {
                                  return {
                                    value: row?._id,
                                    label: row?.name
                                  }
                                })]}
                                value={
                                  values.category ?
                                    {
                                      value: rows?.find((row) => row._id === values.category)?._id,
                                      label: rows?.find((row) => row._id === values.category)?.name
                                    }
                                    : ""
                                }
                                placeholder="All"
                                styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }) }}
                                onBlur={() => setFieldTouched("category")}
                                name="category"
                                onChange={(value) => setFieldValue("category", value.value)}
                              />
                            </FormControl>{" "}
                            <br />
                          </div>
                        </div>
                      </FormControl>

                      <FormControl
                        style={{
                          width: '100%',
                          margingLeft: '8px',
                          padingLeft: '8px',
                          '&.MuiInputLabel-shrink': {
                            transform: 'translate(0, 1.5px) scale(0)',
                          },
                          '&.MuiFormLabel-root': {
                            padingLeft: '8px',
                          },
                        }}>
                        <div className={classes.formLable}>
                          Select Report
                        </div>

                        <Selects
                          options={[{
                            label: "Name", options: priceList?.map((price) => {
                              return {
                                value: price?._id,
                                label: price?.priceListName,
                                priceListName: price.priceListName,
                                _id: price._id,
                                rate: price.rate,
                                shortName: price.shortName,
                              }
                            })
                          }]}
                          isMulti
                          value={investigation}
                          components={{ Option, GroupHeading }}
                          styles={{ container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "28px" }), control: (styles) => ({ ...styles, padding: "3px 0" }) }}
                          onChange={(value) => handleInvestigation(value)}
                        />
                        {
                          investigation.map((price, i) => (
                            <div style={{
                              width: '100%',
                              display: 'grid',
                              marginBottom: '10px',
                              border: '1px solid rgba(201, 201, 201, 1)',
                              borderRadius: '4px',
                              backgroundColor: 'rgba(201, 201, 201, 0.15)',
                              justifyContent: "space-between",
                              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                              padding: "0px 10px"
                            }}>
                              <List>{price?.priceListName}</List>
                              <List>{price?.shortName}</List>
                              <List>₹{price?.rate}</List>
                              <List>Plain</List>
                              <List style={{ margin: '', cursor: "pointer", justifySelf: "flex-end" }} onClick={() => removeInvestigation(i)}>
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_75_4396)">
                                    <path d="M5.92578 17.3074L17.2098 6.02344M5.92578 6.02344L17.2098 17.3074" stroke="#B82C3A" stroke-width="1.88067" stroke-linecap="round" stroke-linejoin="round" />
                                  </g>
                                  <rect x="0.645773" y="0.872336" width="21.146" height="21.146" rx="3.84321" stroke="#B82C3A" stroke-width="0.854047" />
                                  <defs>
                                    <clipPath id="clip0_75_4396">
                                      <rect x="0.21875" y="0.445312" width="22" height="22" rx="4.27023" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </List>
                            </div>
                          ))
                        }

                        <div style={{
                          width: '100%',
                          marginBottom: '60px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'space-between',
                        }}>
                          <div>Investigation count ({investigation.length})</div>
                          <div>Total amount: ₹{payment.bills[0].totalAmount}</div>
                        </div>
                      </FormControl>


                      <FormControl style={{ width: "100%" }}>
                        <div className={classes.formDiv1}>
                          <div className={classes.formDiv2} style={{ width: "100%" }}>
                            <h4 style={{
                              fontSize: '19px',
                              color: '##181C32',
                              fontWeight: '500',
                              paddingBottom: '20px',
                            }}>Sample </h4>


                            <Box style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                              <Box style={{ width: "50%" }}>
                                <div className={classes.formLable}>
                                  Sample
                                </div>
                                <FormControl style={{ width: "100%" }}>

                                  <Selects
                                    options={sampleType}
                                    value={
                                      sampleCollection[0].sampleType ?
                                        {
                                          value: sampleType?.find((sampleType) => sampleType.value == sampleCollection[0].sampleType)?.value,
                                          label: sampleType?.find((sampleType) => sampleType.value == sampleCollection[0].sampleType)?.label
                                        }
                                        : ""
                                    }
                                    styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "8px" }) }}
                                    onChange={(value) => handleSampleCollection("sampleType", value.value)}
                                  />
                                </FormControl>{" "}

                                <div className={classes.formLable}>
                                  Date & time
                                </div>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                      onChange={(value) => handleSampleCollection("date", value.$d)}
                                      value={dayjs(sampleCollection[0].date)}
                                      sx={{ marginTop: "7px", height: "60px", overflow: "hidden", "& input": { padding: "9.5px 14px !important", fontSize: "14px !important" } }} />
                                  </DemoContainer>

                                  {/* <MobileDatePicker sx={formselect} onChange={(value) => setFieldValue("validity", value.$d)}  /> */}
                                </LocalizationProvider>
                                <br />
                              </Box>
                              <Box style={{ width: "50%" }}>

                                <div className={classes.formLable}>
                                  Sample status
                                </div>
                                <FormControl style={{ width: "100%" }}>

                                  <Selects
                                    options={sampleStatus}
                                    value={
                                      sampleCollection[0].sampleStatus ?
                                        {
                                          value: sampleStatus?.find((sampleStatus) => sampleStatus.value == sampleCollection[0].sampleStatus)?.value,
                                          label: sampleStatus?.find((sampleStatus) => sampleStatus.value == sampleCollection[0].sampleStatus)?.label
                                        }
                                        : ""
                                    }
                                    styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "8px" }) }}
                                    onChange={(value) => handleSampleCollection("sampleStatus", value.value)}
                                  />
                                </FormControl>{" "}
                                <br />
                              </Box>
                            </Box>
                          </div>
                        </div>{" "}
                        <div className={classes.formDiv4} style={{ marginRight: '8%' }}>
                          <Buttons
                            className={classes.cancelButton}
                            onClick={() => navigate("/list-patient")}
                          >
                            Cancel
                          </Buttons>
                          <Buttons
                            onClick={() => {
                              setTabToMove("tab3")
                              setSubmit(true)
                              handleSubmit()
                            }
                            }
                            className={classes.submitButton}>
                            Next Step
                          </Buttons>
                        </div>
                      </FormControl>


                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="tab3" sx={{}}>
                  <div>
                    <div className={classes.formMain}>
                      <FormControl style={{ width: "100%" }}>
                        <div className={classes.formDiv1}>
                          <div className={classes.formDiv2}>
                            <div className={classes.formHeading}>
                              Payment details
                            </div>
                            <div className={classes.formLable}>
                              Free of cost:
                              <Checkbox
                                {...label}
                                color="default"
                                onChange={(e) => handlePayment("freeOfCost", "freeOfCost", e.target.checked)}
                                name="freeOfCost"
                              />
                            </div>{" "}
                            <br />
                            <Box style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                              <Box style={{ width: "50%" }}>
                                <div className={classes.formLable}>
                                  Total Bill amount
                                </div>
                                <Input
                                  type="number"
                                  placeholder="Enter here"
                                  disabled={payment.freeOfCost}
                                  className={classes.formInput}
                                  value={payment.bills[0].totalAmount}
                                />{" "}
                                <br />
                                <div className={classes.formLable}>
                                  Discount amount
                                </div>
                                <Input
                                  type="number"
                                  placeholder="Enter Discount here"
                                  className={classes.formInput}
                                  value={payment.bills[0].discount}
                                  name="discount"
                                  onChange={(e) => handlePayment("bill", "discount", e.target.value)}
                                  disabled={payment.freeOfCost}
                                />{" "}
                                <br />
                                <div className={classes.formLable}>
                                  Net amount to pay
                                </div>
                                <Input
                                  type="text"
                                  placeholder="Enter net amount here"
                                  className={classes.formInput}
                                  value={payment.bills[0].netAmount}
                                  disabled={payment.freeOfCost}
                                />{" "}
                                <br />
                                <div className={classes.formLable}>
                                  Paid amount
                                </div>
                                <Input
                                  type="text"
                                  placeholder="Enter here"
                                  className={classes.formInput}
                                  name="paidAmount"
                                  value={payment.deposits[0].paidAmount}
                                  onChange={(e) => handlePayment("deposit", "paidAmount", e.target.value)}
                                  disabled={payment.freeOfCost}
                                />{" "}
                                <br />

                                <br />
                              </Box>
                              <Box style={{ width: "50%" }}>
                                <div className={classes.formDiv2}>
                                  <div
                                    className={classes.formLable}
                                  >
                                    Payment mode
                                  </div>
                                  <FormControl style={{ width: "95%" }}>
                                    <Selects
                                      options={paymentMode}
                                      value={
                                        payment.deposits[0].mode ?
                                          {
                                            value: paymentMode?.find((paymentMode) => paymentMode.value == payment.deposits[0].mode)?.value,
                                            label: paymentMode?.find((paymentMode) => paymentMode.value == payment.deposits[0].mode)?.label
                                          }
                                          : ""
                                      }
                                      styles={{ input: (styles) => ({ ...styles, width: "95% !important" }), container: (styles) => ({ ...styles, marginTop: '11px', marginBottom: "8px" }) }}
                                      onChange={(value) => handlePayment("deposit", "mode", value.value)}
                                    />
                                  </FormControl>{" "}

                                  <div className={classes.formLable}>
                                    Due amount
                                  </div>
                                  <Input
                                    type="text"
                                    placeholder="Enter here"
                                    className={classes.formInput}
                                    value={payment.bills[0].dueAmount}
                                    disabled={payment.freeOfCost}
                                  />{" "}
                                  <br />
                                  <div className={classes.formLable}>
                                    FOC/Discount reason
                                  </div>
                                  <Input
                                    type="text"
                                    placeholder="Enter Discount reason here"
                                    className={classes.formInput}
                                    onChange={(e) => handlePayment("bill", "discountReason", e.target.value)}
                                    name="discountReason"
                                    value={payment.bills[0].discountReason}
                                    disabled={payment.freeOfCost}
                                  />
                                  <br />
                                </div>
                              </Box>
                            </Box>
                          </div>
                        </div>
                        <div className={classes.formDiv4}>
                          <Buttons
                            className={classes.cancelButton}
                            onClick={() => navigate("/list-patient")}
                          >
                            Cancel
                          </Buttons>
                          <Buttons
                            className={classes.submitButton}
                            onClick={registerPatience}
                          >
                            Submit
                          </Buttons>
                        </div>
                      </FormControl>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </div>
      </div >
    </div >
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
          <p>{props.data.label}</p>
          <p>{props.data._id}</p>
          <p>₹{props.data.rate}</p>
        </div>
      </components.Option>
    </>
  )
}

const GroupHeading = (props) => {
  return (
    <components.GroupHeading {...props}>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr 1fr", alignItems: "center" }}>
        <p>Test Name</p>
        <p>Test Id</p>
        <p>Rate/Price</p>
      </div>
    </components.GroupHeading>
  )
}

export default PatientInformationForm;