import React, { useEffect, useRef, useState } from "react";
import { headerStyles } from "../../Styles/HeaderStyle";
import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../Shared/Buttons";
import Popover from '@mui/material/Popover';
import MenuItem from "@mui/material/MenuItem";
import { toast } from 'react-toastify';
import ListItemText from "@mui/material/ListItemText";

const links = {
  "Doctor": ["add-doctor", "register-doctor", "edit-doctor", "doctor"],
  "Users": ["add-user", "edit-user", "register-user", "AccessRight", "view-user"],
  "SampleCollector": ["register-collector", "add-collector", "edit-collector"],
  "Test": ["register-new-test", "add-test", "edit-test"],
  "ReportGroup": ["add-report-group", "register-report-group", "edit-report-format"],
  "ReportFormat": ["register-report-format", "add-report-format", "edit-reports-format", "view-reports-format"],
  "PriceList": ["add-price-list", "register-price-list", "edit-price-list", "view-price-list"],
  "AccountList": ["get-account-list", "add-new-account", "edit-account-list"],
  "SampleFrom": ["get-sample-list", "register-sample-list", "edit-sample-list"],
  "Patients": ["list-patient", "add-patience", "edit-patience", "appointment-view", "patient-report"],
  "PatientCards": ["add-patience-cards", "patience-cards", "edit-patient-card"],
  "Samplecollectors": ["assign-collector"],
  "Visits": ["add-visit", "visitor-book", "edit-visiting-patience"],
  "Payments": ["payments"],
  "Doctor-wise": ["doctor-wise-payments"],
  "Macro": ["macro"],
  "Signature": ["signatures"]
}

const tabs = {
  "Accounts": [
    ...links["Doctor"], ...links["Users"], ...links["SampleCollector"], ...links["Signature"]
  ],
  "MasterData": [
    ...links["Test"], ...links["Macro"], ...links["ReportGroup"], ...links["ReportFormat"], ...links["PriceList"], ...links["AccountList"], ...links["SampleFrom"]
  ]
}

const restrictedTabs = {
  user: ["Accounts", "MasterData"],
  admin: []
}

const Header = () => {
  const [token, setToken] = useState(localStorage.getItem("logintoken") || "");



  const isActive = true;
  const [value, setValue] = React.useState("1");
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("")
  const [showNavbar, setShowNavbar] = useState(false);
  const [hoverThrough, setHoverThrough] = useState(null)

  const handleMouseEnter = (hoverThrough) => {
    setShowNavbar(true); // Show navbar
    setHoverThrough(hoverThrough)
  };

  const handleMouseLeave = () => {
    setShowNavbar(false); // Hide navbar
    setHoverThrough(null)
  };

  const pathname = location.pathname.split("/")[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (token === null) setToken(localStorage.getItem("logintoken"));

    if (!token && pathname !== "/") {
      navigate("/")
    }
  }, []);

  const headclasses = headerStyles();

  const user = localStorage.getItem("user");
  const user_role = localStorage.getItem("role");


  useEffect(() => {
    setRole(user_role)
  }, [user_role])

  let username = user?.split("@")[0];
  let capitalizedUsername = username?.charAt(0).toUpperCase() + username?.slice(1);

  const logout = () => {
    handleClose();
    localStorage.removeItem("logintoken");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    toast.success("Logged out Successfully");
    setToken("")
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1500)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (tabs["Accounts"]?.includes(pathname)) {
      setValue("1")
    } else if (tabs["MasterData"]?.includes(pathname)) {
      setValue("2")
    } else {
      setValue("3")
    }
  }, [pathname])


  console.log("dropdown------", showNavbar)

  return (
    token && <div className={headclasses.headerMain}>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "16%",
            backgroundColor: "#B82C3A",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#F4FFF3",
            fontSize: "18px",
            fontStyle: "bold",
            paddingTop: '20px',
            textTransform: 'uppercase',
          }}
        >
          Nagalikar
        </div>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{
              backgroundColor: "#B82C3A",
              height: "52px",
              paddingTop: '12px',
              textTransform: 'none',
            }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                style={{
                  height: "54px",
                  border: "none",
                  color: "#F4FFF3",
                  fontFamily: 'Poppins',
                }}
                className={headclasses.tabs}
              >
                {
                  !restrictedTabs[user_role]?.includes("Accounts") ?
                    < Tab
                      className={headclasses.tablist}
                      label="Accounts"
                      value="1"
                      onClick={() => navigate('/register-doctor')}
                      onMouseEnter={(e) => handleMouseEnter("1")}
                      onMouseLeave={handleMouseLeave}
                    />
                    : <></>
                }
                {
                  !restrictedTabs[user_role]?.includes("MasterData") ?
                    <Tab
                      className={headclasses.tablist}
                      label="Master data"
                      value="2"
                      onClick={() => navigate('/add-test')}
                      onMouseEnter={(e) => handleMouseEnter("2")}
                      onMouseLeave={handleMouseLeave}
                    />
                    : <></>
                }
                <Tab
                  className={headclasses.tablist}
                  label="Patients"
                  value="3"
                  onClick={() => navigate('/list-patient')}
                  onMouseEnter={(e) => handleMouseEnter("3")}
                  onMouseLeave={handleMouseLeave}
                />
              </TabList>
            </Box>
            {
              value == "1" && <TabPanel value="1">
                <div
                  onMouseEnter={(e) => handleMouseEnter("1")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "1" ? "block" : 'none' }}>
                  <Link to="/register-doctor" className={links["Doctor"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      active={isActive}
                      className={headclasses.customButton}
                    >
                      Doctor
                    </Button>
                  </Link>
                  <Link to="/register-user" className={links["Users"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button className={headclasses.customButton}>Users</Button>
                  </Link>
                  <Link to="/register-collector" className={links["SampleCollector"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button className={headclasses.customButton}>
                      Sample collector
                    </Button>
                  </Link>
                  <Link to="/signatures" className={links["Signature"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button className={headclasses.customButton}>
                      Signature
                    </Button>
                  </Link>
                </div>
              </TabPanel>
            }
            {
              value == "2" && <TabPanel value="2">
                <div
                  onMouseEnter={(e) => handleMouseEnter("2")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "2" ? "block" : 'none' }}>
                  <Link to="/add-report-group" className={links["ReportGroup"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Report Group
                    </Button>
                  </Link>
                  <Link to="/add-test" active={isActive} className={links["Test"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button className={headclasses.customButton}>
                      Test
                    </Button>
                  </Link>
                  <Link to="/add-report-format" className={links["ReportFormat"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Report Format
                    </Button>
                  </Link>
                  <Link to="/add-price-list" className={links["PriceList"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Price list
                    </Button>
                  </Link>

                  <Link to="/get-account-list" className={links["AccountList"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Account List
                    </Button>
                  </Link>

                  <Link to="/get-sample-list" className={links["SampleFrom"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Sample From
                    </Button>
                  </Link>

                  <Link to="/macro" className={links["Macro"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      // to="/my-page"
                      className={headclasses.customButton}
                    >
                      Macro
                    </Button>
                  </Link>
                </div>
              </TabPanel>
            }

            {
              value == "3" && <TabPanel value="3">
                <div
                  onMouseEnter={(e) => handleMouseEnter("3")}
                  onMouseLeave={handleMouseLeave}
                  style={{ padding: 10, background: "#fff", zIndex: 1000, position: "absolute", top: 52, borderRadius: 5, boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)", display: showNavbar && hoverThrough == "3" ? "block" : 'none' }}>
                  <Link to="/list-patient" className={links["Patients"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      to="/list-patient"
                      className={headclasses.customButton}
                    >
                      Patients
                    </Button>
                  </Link>
                  <Link to="/patience-cards" className={links["PatientCards"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      to="/patience-cards"
                      className={headclasses.customButton}
                    >
                      Patient cards
                    </Button>
                  </Link>

                  <Link to="/assign-collector" className={links["Samplecollectors"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      to="/my-page"
                      className={headclasses.customButton}
                    >
                      Sample collectors
                    </Button>
                  </Link>

                  <Link to="/visitor-book" className={links["Visits"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      // component={Link}
                      to="/my-page"
                      className={headclasses.customButton}
                    >
                      Visits
                    </Button>
                  </Link>
                  <Link to="/payments" className={links["Payments"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      className={headclasses.customButton}
                    >
                      Payments
                    </Button>
                  </Link>

                  <Link to="/doctor-wise-payments" className={links["Doctor-wise"]?.includes(pathname) ? headclasses.linkActive : ""}>
                    <Button
                      className={headclasses.customButton}
                    >
                      Doctor Wise Payments
                    </Button>
                  </Link>

                </div>
              </TabPanel>
            }

          </TabContext>
        </Box>
        <div
          style={{
            width: "25%",
            backgroundColor: "#B82C3A",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#F4FFF3",
              textAlign: "right",
              marginRight: "6px",
              cursor: "pointer",
              position: "relative",
              textTransform: 'uppercase',
            }}
            onClick={handleClick}
          >
            <span>{capitalizedUsername}</span>
            <span>{role}</span>
          </div>
          <Popover
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                backgroundColor: '#273142',
                color: '#FFFFFF',
                borderRadius: '10px',
                padding: "10px",
                width: '100px',
              },
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={logout}>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Popover>
          <p
            style={{
              color: "#FFFFFF",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "20px",
              fontWeight: "bold",
              borderRadius: "6px",
              background: "rgba(255, 255, 255, 0.08)"
            }}
          >
            {capitalizedUsername && capitalizedUsername?.charAt(0)?.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
