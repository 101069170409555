import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { API } from "../../config";
import { formatTime, formatedDate, formatedDateInDigit, removeQuotes } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import Api from "../../helper/api";
import DocViewer from "../Shared/DocViewer";
import DocEditor from "../Shared/Editor";
import Buttons from "./Buttons";
import { formStyles } from "../../Styles/Formstyleclone";
import { toast } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    width: "900.88px",
    padding: "0 10px",
    height: "100%",
    overflow: "scroll",
    height: "100%",
    display: "block",

    borderRadius: "6px",
    "&:focus": {
        outline: "none",
    },
};

const LayoutEditor = () => {
    const [open, setOpen] = React.useState(true);
    const api = new Api();
    const classes = formStyles();
    const params = useParams();
    const [patience, setPatience] = useState();
    const [report, setReport] = useState();
    const [test, setTest] = useState([]);
    const [macro, setMacro] = useState(null);
    const [value, setValue] = useState(null);
    const [initialValue, setInitialValue] = useState(null);

    const handleOpen = () => setOpen(true);
    const navigate = useNavigate();
    const reportContent = useRef();


    const fetchData = async () => {
        const { data, status } = await api.fetchPatientById({}, { id: params.id })
        setPatience(data);

        const investigation = data?.investigation?.find((item) => item._id === parseInt(params.reportId))
        const macro = data?.testResult?.find((item) => item.investigationId === parseInt(params.reportId))
        setReport(investigation)

        if (macro) {
            setMacro(macro)

            if (macro.layout) {
                setValue(macro?.layout)
                setInitialValue(macro?.layout)
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [params.id]);

    useEffect(() => {
        const fetchTest = async () => {
            try {
                let test = [];
                let testInfo = {}
                const isMale = patience?.gender === "male" ? true : false
                const age = patience?.age;

                for (let i = 0; i < report?.reportFormat?.length; i++) {
                    const id = report?.reportFormat[i]

                    const { data, status } = await api.fetchReportById({}, { id });

                    if (status === 200) {
                        if (data.report?.reportsubcategory?.length > 0) {
                            for (let i = 0; i < data?.report?.reportsubcategory?.length; i++) {
                                const testId = data?.report?.reportsubcategory[i]?._id;

                                const testExist = test.find((test) => test._id === testId)

                                if (!testExist) {

                                    test = [
                                        ...test,
                                        {
                                            ...data?.report?.reportsubcategory[i],
                                            reportName: data?.report?.reportName,
                                            reportId: data?.report?._id
                                        }
                                    ]
                                }
                            }
                        }
                    }

                    let testClone = test.reduce((acc, curr) => {
                        if (curr.id) {
                            acc[curr.id] = curr
                        }

                        return acc
                    }, {})

                    testInfo = testClone

                    if (data.report?.layout) {
                        const isLow = (key, data, isMale, age) => {
                            const test = testInfo[key]

                            if (!data) {
                                return false
                            }

                            if (!test) {
                                return false
                            }

                            if (isMale) {
                                const range = test.rangeForMale
                                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                let ran = {};

                                for (let i = 0; i < rangeClone.length; i++) {
                                    let curr = rangeClone[i].ageUpto

                                    if (Object.keys(ran).length === 0) {
                                        if (curr >= age) {
                                            ran = rangeClone[i];
                                        }
                                    }
                                }

                                if (!ran?.low ?? false) {
                                    return false
                                }

                                if (data >= ran.low) {
                                    return false
                                } else {
                                    return true
                                }
                            } else {
                                const range = test.rangeForFemale
                                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                let ran = {}

                                for (let i = 0; i < rangeClone.length; i++) {
                                    let curr = rangeClone[i].ageUpto
                                    if (Object.keys(ran).length === 0) {
                                        if (curr >= age) {
                                            ran = rangeClone[i];
                                        }
                                    }
                                }

                                if (!ran?.low ?? false) {
                                    return false
                                }

                                if (data >= ran.low) {
                                    return false
                                } else {
                                    return true
                                }
                            }
                        }

                        const isHigh = (key, data, isMale, age) => {
                            const test = testInfo[key]

                            if (!data) {
                                return false
                            }

                            if (!test) {
                                return false
                            }

                            if (isMale) {
                                const range = test.rangeForMale
                                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                let ran = {}

                                for (let i = 0; i < rangeClone.length; i++) {
                                    let curr = rangeClone[i].ageUpto
                                    if (Object.keys(ran).length === 0) {
                                        if (curr >= age) {
                                            ran = rangeClone[i];
                                        }
                                    }
                                }

                                if (!ran?.high ?? false) {
                                    return false
                                }

                                if (data <= ran.high) {
                                    return false
                                } else {
                                    return true
                                }

                            } else {
                                const range = test.rangeForFemale
                                let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                let ran = {}

                                for (let i = 0; i < rangeClone.length; i++) {
                                    let curr = rangeClone[i].ageUpto
                                    if (Object.keys(ran).length === 0) {
                                        if (curr >= age) {
                                            ran = rangeClone[i];
                                        }
                                    }
                                }

                                if (!ran?.high ?? false) {
                                    return false
                                }

                                if (data <= ran.high) {
                                    return false
                                } else {
                                    return true
                                }
                            }
                        }

                        const replaceResult = (text, data) => {
                            if (!data) return text; // Return original text if data is not available
                            let textClone = JSON.parse(JSON.parse(text))
                            let textItems = textClone["sec"][0]["b"]

                            for (let i = 0; i < textItems.length; i++) {
                                const subItems = textItems[i]["i"];

                                for (let z = 0; z < subItems?.length; z++) {
                                    const thirdSubItems = JSON.stringify(subItems[z]);

                                    thirdSubItems.replace(/\{\!(.*?)\!\}/g, (_, key) => {
                                        const _id = testInfo[key]?._id;

                                        if (!_id) {
                                            return
                                        }
                                        const value = data.find((data) => data.testId === _id)?.result || ""

                                        const low = isLow(key, value, isMale, age)
                                        const high = isHigh(key, value, isMale, age)

                                        if (low || high) {
                                            textClone["sec"][0]["b"][i]["i"][z]["cf"]["b"] = true
                                        }
                                    })
                                }
                            }

                            return JSON.stringify(JSON.stringify(textClone)).replace(/\{\!(.*?)\!\}/g, (_, key) => {
                                const _id = testInfo[key]?._id

                                if (!_id) {
                                    return ""
                                }
                                const value = data.find((data) => data.testId === _id)?.result || ""

                                return removeQuotes(value)
                            });
                        };

                        const replaceLowRange = (text, data, isMale, age) => {
                            if (!data) return text; // Return original text if data is not available
                            return text.replace(/\|(\w+)\|/g, (_, key) => {
                                const test = testInfo[key]
                                if (!test) {
                                    return ""
                                }

                                if (isMale) {
                                    const range = test.rangeForMale
                                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                    let ran = {}

                                    for (let i = 0; i < rangeClone.length; i++) {
                                        let curr = rangeClone[i].ageUpto
                                        if (Object.keys(ran).length === 0) {
                                            if (curr >= age) {
                                                ran = rangeClone[i];
                                            }
                                        }
                                    }

                                    return ran?.low ?? ""
                                } else {
                                    const range = test.rangeForFemale
                                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                    let ran = {}

                                    for (let i = 0; i < rangeClone.length; i++) {
                                        let curr = rangeClone[i].ageUpto
                                        if (Object.keys(ran).length === 0) {
                                            if (curr >= age) {
                                                ran = rangeClone[i];
                                            }
                                        }
                                    }

                                    return ran?.low ?? ""
                                }
                            });
                        }

                        const replaceHighRange = (text, data, isMale, age) => {
                            if (!data) return text; // Return original text if data is not available
                            return text.replace(/\$(\w+)\$/g, (_, key) => {
                                const test = testInfo[key]
                                if (!test) {
                                    return ""
                                }

                                if (isMale) {
                                    const range = test.rangeForMale
                                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                    let ran = {}

                                    for (let i = 0; i < rangeClone.length; i++) {
                                        let curr = rangeClone[i].ageUpto
                                        if (Object.keys(ran).length === 0) {
                                            if (curr >= age) {
                                                ran = rangeClone[i];
                                            }
                                        }
                                    }

                                    return ran?.high ?? ""
                                } else {
                                    const range = test.rangeForFemale
                                    let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                                    let ran = {}

                                    for (let i = 0; i < rangeClone.length; i++) {
                                        let curr = rangeClone[i].ageUpto
                                        if (Object.keys(ran).length === 0) {
                                            if (curr >= age) {
                                                ran = rangeClone[i];
                                            }
                                        }
                                    }

                                    return ran?.high ?? ""
                                }
                            });
                        }

                        let layout = replaceResult(data.report.layout, macro.testResult)
                        layout = replaceLowRange(layout, macro.testResult, isMale, age)
                        layout = replaceHighRange(layout, macro.testResult, isMale, age)

                        let newLayout = JSON.parse(layout);

                        let cloneLayout = JSON.parse(newLayout)

                        const patientData = {
                            sample: patience?.sampleNo,
                            bill: `${patience?._id}${report?._id}`,
                            name: `${patience?.firstName} ${patience?.lastName}`,
                            age: `${patience?.age}yrs / ${patience?.gender}`,
                            referredBy: patience?.refferedBy?.name || "Self",
                            date: formatedDateInDigit(new Date())
                        }

                        const headerContent = [
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "as": 1,
                                    "ls": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 387,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "fsz": "11",
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "fszbi": 9,
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Sample No"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |sample|"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Bill No"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |bill|"
                                    }
                                ]
                            },
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "as": 1,
                                    "ls": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 387,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "fsz": "11",
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "fszbi": 9,
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Patient name"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "fszbi": 9,
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |name|"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "fszbi": 9,
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Age/Sex"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "fszbi": 9,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |age|"
                                    }
                                ]
                            },
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "as": 0,

                                    "ls": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 387,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "fsz": "11",
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Referred by"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |referredBy|"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Date"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "fsz": "11",
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": ": |date|"
                                    }
                                ]
                            },
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "rin": -4.5,
                                    "ta": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 333,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "----------------------------------------------------------------------------------------------------------------------------------"
                                    }
                                ]
                            },
                        ]

                        const replacePatientData = (text) => {
                            return text.replace(/\|(\w+)\|/g, (_, key) => {
                                const value = patientData[key]
                                return value
                            });
                        }

                        let headerContentClone = replacePatientData(JSON.stringify(headerContent))
                        let headerContentValue = JSON.parse(headerContentClone)

                        for (let i = headerContentValue.length - 1; i >= 0; i--) {
                            const currEle = headerContentValue[i];
                            // if (i !== 0) {
                            cloneLayout["sec"][0]["b"].unshift(currEle)
                            // }
                        }

                        const footerData = {
                            enteredBy: macro?.enteredBy?.replace(/^\w/, c => c.toUpperCase()),
                            verifiedBy: macro?.verifiedBy?.replace(/^\w/, c => c.toUpperCase()),
                            sign: report?.sign || ""
                        }

                        const signContent = [
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "rin": -4.5,
                                    "ta": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 333,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "fsz": 13,
                                    "fszbi": 13
                                },
                                "i": []
                            },
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "rin": -4.5,
                                    "ta": 2,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 333,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "fsz": 13,
                                    "fszbi": 13
                                },
                                "i": [
                                    {
                                        "cf": {},
                                        "img": "0",
                                        "imf": 0,
                                        "w": 97.87504000000001,
                                        "h": 92.03173000000001,
                                        "n": "image1.png",
                                        "v": 1,
                                        "ws": 32.222237,
                                        "hs": 38.108376,
                                        "vp": 0,
                                        "vo": 4,
                                        "va": 0,
                                        "hp": 0,
                                        "ho": 4,
                                        "ha": 0,
                                        "ao": 1,
                                        "tws": 0,
                                        "twt": 0,
                                        "b": 0,
                                        "db": 0,
                                        "dl": 9,
                                        "dr": 9,
                                        "dt": 0,
                                        "lc": 1,
                                        "z": 2147483647
                                    }
                                ]
                            }
                        ]

                        if (footerData.sign) {
                            cloneLayout["imgs"]["0"] = [footerData.sign]

                            for (let i = 0; i < signContent.length; i++) {
                                const currEle = signContent[i];
                                // if (i !== 0) {
                                cloneLayout["sec"][0]["b"].push(currEle)
                                // }
                            }
                        }

                        const footerContent = [

                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "rin": -4.5,
                                    "ta": 1,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 333,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "---------End of report---------"
                                    }
                                ]
                            },
                            {
                                "pf": {
                                    "bdrs": {
                                        "tp": {},
                                        "lt": {},
                                        "rg": {},
                                        "bt": {},
                                        "h": {},
                                        "v": {}
                                    },
                                    "rin": -4.5,
                                    "stn": "Normal",
                                    "lif": {},
                                    "tb": [
                                        {
                                            "p": 333,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 288,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 99,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        },
                                        {
                                            "p": 234,
                                            "dp": 0,
                                            "tj": 0,
                                            "tl": 0
                                        }
                                    ]
                                },
                                "cf": {
                                    "ff": "Arial",
                                    "ffbi": "Arial",
                                    "ffa": "Arial",
                                    "ffnfe": "Arial",
                                    "fffe": "Arial"
                                },
                                "i": [
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "b": true,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Entered by: |enteredBy|"
                                    },
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "\t"
                                    },
                                    {
                                        "cf": {
                                            "ff": "Arial",
                                            "bi": false,
                                            "b": true,
                                            "ffbi": "Arial",
                                            "ffa": "Arial",
                                            "ffnfe": "Arial",
                                            "fffe": "Arial"
                                        },
                                        "tlp": "Verified by: |verifiedBy|"
                                    }
                                ]
                            }
                        ]

                        const replaceFooterData = (text) => {
                            return text.replace(/\|(\w+)\|/g, (_, key) => {
                                const value = footerData[key]
                                return value || ""
                            });
                        }

                        let footerContentClone = replaceFooterData(JSON.stringify(footerContent))
                        let footerContentValue = JSON.parse(footerContentClone);

                        for (let i = 0; i < footerContentValue.length; i++) {
                            const currEle = footerContentValue[i];
                            // if (i !== 0) {
                            cloneLayout["sec"][0]["b"].push(currEle)
                            // }
                        }

                        setTest((prev) => {
                            return { ...prev, [id]: { layout: JSON.stringify(cloneLayout) } }
                        })

                        setInitialValue(JSON.stringify(cloneLayout))
                        setValue(JSON.stringify(cloneLayout))
                    }
                }

            } catch (err) {
                console.log(err)
            }
        }

        if (report?.reportFormat?.length > 0 && macro && !macro?.layout) {
            fetchTest()
        }
    }, [report?._id, macro])

    const handleClose = () => {
        setOpen(false);
        navigate(`/list-patient`);
    };

    const saveLayout = async () => {
        if (value) {
            try {
                const { data, status } = await api.updateLayout({
                    investigationId: params.reportId,
                    patient: params.id,
                    layout: value
                })

                if (status === 200) {
                    toast.success("Saved Successfully")
                    handleClose()
                }
            } catch (err) {
                toast.error("Error Saving!")
            }
        } else {
            handleClose()
        }
    }

    const handleChange = (content) => {
        setValue(content)
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="overflow-y-scroll "
        >
            <Box sx={style} className="">
                <div >
                    <header className="flex justify-between border-b border-[#C9C9C9] px-1 py-1 ">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Report preview
                        </Typography>
                        <button onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </header>
                    <main className=" px-0 py-0 " id="report-content">

                        <DocEditor
                            initialValue={JSON.parse(initialValue)}
                            setValue={setValue}
                            value={value}
                            onChange={(content) => handleChange(content)}
                        />

                        <div
                            style={{
                                marginTop: '70px',
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'right',
                                marginRight: '4%',
                                marginBottom: "20px"
                            }}>
                            <Buttons className={classes.cancelButton}
                                onClick={handleClose}
                            >
                                Cancel
                            </Buttons>
                            <Buttons
                                className={classes.submitButton}
                                onClick={saveLayout}
                            >
                                Save
                            </Buttons>
                        </div>
                    </main>
                </div>
            </Box>
        </Modal>
    );
};

export default LayoutEditor;