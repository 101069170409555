import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  TableCell
} from "@material-ui/core";
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';


const ViewUser = () => {
  const api = new Api();
  const params = useParams();
  const [data, setData] = useState(null);
  const tableclasses = tableStyles();
  const classes = formStyles();
  const navigate = useNavigate();
  const [assign, setAssign] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, status } = await api.fetchUserByID({}, { id: params.id })

        if (status === 200) {
          setData(data.singleuser)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchUser();
  }, [refresh])
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>{data?.name}</div>
            <div className={classes.viewHead}>
              <div className={`${classes.formspecification}`}
                style={{ marginLeft: '50px', marginRight: '50px' }}
              >
                User ID: {params?.id}
              </div>
              <div className={classes.formspecification} style={{ display: 'flex', alignItems: 'center' }}>
                Status:
                <TableCell className={tableclasses.customTableCell}>
                  {
                    data?.status === "active" || data?.status === "Active" ?
                      <Buttons className={tableclasses.customActive}>
                        <div style={{ color: 'green' }}>
                          Active
                        </div>
                      </Buttons>
                      :
                      <Buttons className={tableclasses.customInActive}>
                        <div style={{ color: 'red' }}>
                          InActive
                        </div>
                      </Buttons>
                  }
                </TableCell>
              </div>
              <Buttons
                className={classes.backToButton}
                style={{ width: "120px", background: "#B82C3A", lineHeight: "15px" }}
                onClick={() => setAssign(true)}
              >
                Assign signature
              </Buttons>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/register-user")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Name</p>
              <p>Email</p>
              <p>Phone</p>
              <p>Role</p>
              <p>AuditLock Days</p>
              <p>Signature</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{data?.name ? data?.name : "NA"}</p>
              <p>{data?.email ? data?.email : "NA"}</p>
              <p>{data?.phone ? data?.phone : "NA"}</p>
              <p>{data?.role ? data?.role : "NA"}</p>
              <p>{data?.auditlockdays ? data?.auditlockdays : "NA"}</p>
              {
                data?.sign &&
                <img src={data.sign.sign} style={{ width: 150, height: 120, objectFit: "cover" }} />
              }
            </div>
          </div>
          <div className={classes.imageside}></div>
        </div>
      </div>
      <AssignSignature setRefresh={setRefresh} refresh={refresh} id={params.id} setAssign={setAssign} assign={assign} />
    </div>
  )
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
};


const AssignSignature = ({ setAssign, assign, id, setRefresh, refresh }) => {
  const tableclasses = tableStyles();
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState([]);
  const api = new Api();
  const [activeSign, setActiveSign] = useState({})

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data, status } = await api.signature().get("/signature")

        if (status === 200) {
          setSign(data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetch();
  }, [])

  const handleSave = async () => {
    if (!activeSign?._id) {
      toast.error("Please select the signature")
      return null
    }

    try {
      const { status } = await api.signature().put(`/edit-user/${id}`, {
        sign: activeSign._id
      })

      if (status === 200) {
        toast.success("Saved successfully")
        setRefresh(!refresh)
        setAssign(false)
      }
    } catch (err) {
      toast.error("Some error occured!")
    }
  }
  return (
    <Modal
      open={assign}
      onClose={() => setAssign(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <div style={{ width: "94%", display: "flex", flexWrap: "wrap", marginBottom: 8, gap: 15 }}>
          {
            sign.map((sign) => (
              <div onClick={() => setActiveSign(sign)} style={{ width: 150, position: "relative", height: 150, padding: 4, borderRadius: 4, border: activeSign._id === sign._id ? "2px solid red" : "1px solid grey", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)" }}>
                <img src={sign.sign} alt="" style={{ width: "100%", height: "calc(100% - 20px)", objectFit: "cover" }} />
                <h5 style={{ textAlign: "center", fontWeight: 500 }}>{sign.name}</h5>
              </div>
            ))
          }
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Buttons className={tableclasses.printButton} disabled={loading} style={{ width: "120px" }} onClick={handleSave}>
            {
              loading ? "Saving.." : "Save"
            }
          </Buttons>
          <Buttons className={tableclasses.printButton} style={{ width: "120px", background: "#fff", color: "#000" }} onClick={() => setAssign(false)}>
            Cancel
          </Buttons>
        </div>
      </Box>
    </Modal>
  )
}

export default ViewUser
