export const formatedDate = (newDate) => {
  const date = new Date(newDate);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const formatTime = (newTime) => {
  const time = new Date(newTime);
  return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

export const formatedDateInDigit = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};


export const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':');
  const dateObj = new Date();
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);

  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return formattedTime;
}



export function removeQuotes(value) {
  // Check if the value is a string and starts and ends with either single or double quotes
  if (typeof value === 'string') {
    if ((value.startsWith('"') && value.endsWith('"')) ||
      (value.startsWith("'") && value.endsWith("'"))) {
      return value.slice(1, -1); // Remove the first and last character (the quotes)
    }
  }
  return value; // Return the value as is if no quotes are found
}
