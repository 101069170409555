import React, { useEffect, useMemo, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit, removeQuotes } from "./../../helper/helper";
import { useReactToPrint } from "react-to-print";
import { stringify } from 'flatted'
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";
import DocEditor from "./Editor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  height: "100%",
  width: "900.88px",
  padding: "0 0px",
  overflow: "scroll",
  display: "block",

  borderRadius: "6px",
  "&:focus": {
    outline: "none",
  },
};

const ReportLayout = ({ handleClose, layoutId, id }) => {
  const api = new Api();
  const classes = formStyles();
  const macroclasses = macroStyles();
  const dispatch = useDispatch()
  const { reFetch } = useSelector(state => state.findPatient)

  const [open, setOpen] = React.useState(true);
  const macros = useRef(null)
  const [patience, setPatience] = useState();
  const [report, setReport] = useState();
  const [macro, setMacro] = useState(null);
  const [test, setTest] = useState([]);
  const [value, setValue] = useState()
  const token = localStorage.getItem("logintoken")

  const fetchData = async () => {
    const { data, status } = await api.fetchPatientById({}, { id: id })
    setPatience(data);

    const investigation = data?.investigation.find((item) => item._id === parseInt(layoutId))
    const macro = data?.testResult?.find((item) => item.investigationId === parseInt(layoutId))
    setReport(investigation)

    if (macro) {
      setMacro(macro)
      if (macro.layout) {
        setValue(macro?.layout)
      }

    } else {
      setMacro(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchTest = async () => {
      try {
        setTest([]);
        let test = [];
        let testInfo = {};
        const isMale = patience?.gender === "male" ? true : false;
        const age = patience?.age;

        for (let i = 0; i < report.reportFormat.length; i++) {
          const id = report?.reportFormat[i]

          const { data, status } = await api.fetchReportById({}, { id });

          if (status === 200) {

            if (data.report?.reportsubcategory.length > 0) {

              for (let i = 0; i < data.report?.reportsubcategory.length; i++) {
                const testId = data.report.reportsubcategory[i]._id;


                const testExist = test.find((test) => test._id === testId)

                if (!testExist) {

                  test = [
                    ...test,
                    {
                      ...data.report.reportsubcategory[i],
                      reportName: data.report?.reportName,
                      reportId: data.report?._id
                    }
                  ]
                }

                let testClone = test.reduce((acc, curr) => {
                  if (curr.id) {
                    acc[curr.id] = curr
                  }

                  return acc
                }, {})

                testInfo = testClone;

                if (data.report?.layout) {


                  const isLow = (key, data, isMale, age) => {
                    const test = testInfo[key]

                    if (!data) {
                      return false
                    }

                    if (!test) {
                      return false
                    }

                    if (isMale) {
                      const range = test.rangeForMale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {};

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto

                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (!ran?.low) {
                        return false
                      }

                      if (data >= ran.low) {
                        return false
                      } else {
                        return true
                      }
                    } else {
                      const range = test.rangeForFemale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (!ran?.low) {
                        return false
                      }

                      if (data >= ran.low) {
                        return false
                      } else {
                        return true
                      }
                    }
                  }

                  const isHigh = (key, data, isMale, age) => {
                    const test = testInfo[key]

                    if (!data) {
                      return false
                    }

                    if (!test) {
                      return false
                    }

                    if (isMale) {
                      const range = test.rangeForMale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (!ran?.high) {
                        return false
                      }

                      if (data <= ran.high) {
                        return false
                      } else {
                        return true
                      }

                    } else {
                      const range = test.rangeForFemale
                      let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                      let ran = {}

                      for (let i = 0; i < rangeClone.length; i++) {
                        let curr = rangeClone[i].ageUpto
                        if (Object.keys(ran).length === 0) {
                          if (curr >= age) {
                            ran = rangeClone[i];
                          }
                        }
                      }

                      if (!ran?.high) {
                        return false
                      }

                      if (data <= ran.high) {
                        return false
                      } else {
                        return true
                      }
                    }
                  }

                  const replaceResult = (text, data) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/\{\!(.*?)\!\}/g, (_, key) => {
                      const _id = testInfo[key]?._id;

                      if (!_id) {
                        return ""
                      }
                      const value = data.find((data) => data.testId === _id)?.result || ""

                      return removeQuotes(value)
                    });
                  };

                  const replaceLowRange = (text, data, isMale, age) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/\|(\w+)\|/g, (_, key) => {
                      const test = testInfo[key]
                      if (!test) {
                        return text
                      }

                      if (isMale) {
                        const range = test.rangeForMale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.low || ""
                      } else {
                        const range = test.rangeForFemale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.low || ""
                      }
                    });
                  }

                  const replaceHighRange = (text, data, isMale, age) => {
                    if (!data) return text; // Return original text if data is not available
                    return text.replace(/\$(\w+)\$/g, (_, key) => {
                      const test = testInfo[key]
                      if (!test) {
                        return text
                      }

                      if (isMale) {
                        const range = test.rangeForMale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.high || ""
                      } else {
                        const range = test.rangeForFemale
                        let rangeClone = [...range].sort((a, b) => a?.ageUpto - b?.ageUpto)
                        let ran = {}

                        for (let i = 0; i < rangeClone.length; i++) {
                          let curr = rangeClone[i].ageUpto
                          if (Object.keys(ran).length === 0) {
                            if (curr >= age) {
                              ran = rangeClone[i];
                            }
                          }
                        }

                        return ran?.high || ""
                      }
                    });
                  }



                  // let layout = JSON.parse(data.report.layout)
                  let layout = replaceResult(data.report.layout, macro?.testResult)
                  // layout = replaceLowRange(layout, macro?.testResult, isMale, age)
                  // console.log("layot-------", JSON.parse(layout))
                  // layout = replaceHighRange(layout, macro?.testResult, isMale, age)
                  // console.log("report---------", JSON.parse(layout))


                  let newLayout = JSON.parse(layout);

                  let cloneLayout = JSON.parse(newLayout)

                  const patientData = {
                    sample: patience?.sampleNo,
                    bill: `${patience?._id}${report?._id}`,
                    name: `${patience?.firstName} ${patience?.lastName}`,
                    age: `${patience?.age}yrs / ${patience?.gender}`,
                    referredBy: patience?.refferedBy?.name || "Self",
                    date: formatedDateInDigit(new Date())
                  }

                  const headerContent = [
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "as": 1,
                        "ls": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 387,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "fsz": "11",
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "fszbi": 9,
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Sample No"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |sample|"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Bill No"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |bill|"
                        }
                      ]
                    },
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "as": 1,
                        "ls": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 387,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "fsz": "11",
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "fszbi": 9,
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Patient name"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "fszbi": 9,
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |name|"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "fszbi": 9,
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Age/Sex"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "fszbi": 9,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |age|"
                        }
                      ]
                    },
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "as": 0,

                        "ls": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 387,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "fsz": "11",
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Referred by"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |referredBy|"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Date"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "fsz": "11",
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": ": |date|"
                        }
                      ]
                    },
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "rin": -4.5,
                        "ta": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 333,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "----------------------------------------------------------------------------------------------------------------------------------"
                        }
                      ]
                    },
                  ]

                  const replacePatientData = (text) => {
                    return text.replace(/\|(\w+)\|/g, (_, key) => {
                      const value = patientData[key]
                      return value
                    });
                  }

                  let headerContentClone = replacePatientData(JSON.stringify(headerContent))
                  let headerContentValue = JSON.parse(headerContentClone)

                  for (let i = headerContentValue.length - 1; i >= 0; i--) {
                    const currEle = headerContentValue[i];
                    // if (i !== 0) {
                    cloneLayout["sec"][0]["b"].unshift(currEle)
                    // }
                  }

                  const footerData = {
                    enteredBy: macro?.enteredBy?.replace(/^\w/, c => c.toUpperCase()),
                    verifiedBy: macro?.verifiedBy?.replace(/^\w/, c => c.toUpperCase()),
                    sign: report?.sign || ""
                  }

                  const signContent = [
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "rin": -4.5,
                        "ta": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 333,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "fsz": 13,
                        "fszbi": 13
                      },
                      "i": []
                    },
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "rin": -4.5,
                        "ta": 2,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 333,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "fsz": 13,
                        "fszbi": 13
                      },
                      "i": [
                        {
                          "cf": {},
                          "img": "0",
                          "imf": 0,
                          "w": 97.87504000000001,
                          "h": 92.03173000000001,
                          "n": "image1.png",
                          "v": 1,
                          "ws": 32.222237,
                          "hs": 38.108376,
                          "vp": 0,
                          "vo": 4,
                          "va": 0,
                          "hp": 0,
                          "ho": 4,
                          "ha": 0,
                          "ao": 1,
                          "tws": 0,
                          "twt": 0,
                          "b": 0,
                          "db": 0,
                          "dl": 9,
                          "dr": 9,
                          "dt": 0,
                          "lc": 1,
                          "z": 2147483647
                        }
                      ]
                    }
                  ]

                  if (footerData.sign) {
                    cloneLayout["imgs"]["0"] = [footerData.sign]

                    for (let i = 0; i < signContent.length; i++) {
                      const currEle = signContent[i];
                      // if (i !== 0) {
                      cloneLayout["sec"][0]["b"].push(currEle)
                      // }
                    }
                  }

                  const footerContent = [

                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "rin": -4.5,
                        "ta": 1,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 333,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "---------End of report---------"
                        }
                      ]
                    },
                    {
                      "pf": {
                        "bdrs": {
                          "tp": {},
                          "lt": {},
                          "rg": {},
                          "bt": {},
                          "h": {},
                          "v": {}
                        },
                        "rin": -4.5,
                        "stn": "Normal",
                        "lif": {},
                        "tb": [
                          {
                            "p": 333,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 288,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 99,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          },
                          {
                            "p": 234,
                            "dp": 0,
                            "tj": 0,
                            "tl": 0
                          }
                        ]
                      },
                      "cf": {
                        "ff": "Arial",
                        "ffbi": "Arial",
                        "ffa": "Arial",
                        "ffnfe": "Arial",
                        "fffe": "Arial"
                      },
                      "i": [
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "b": true,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Entered by: |enteredBy|"
                        },
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "\t"
                        },
                        {
                          "cf": {
                            "ff": "Arial",
                            "bi": false,
                            "b": true,
                            "ffbi": "Arial",
                            "ffa": "Arial",
                            "ffnfe": "Arial",
                            "fffe": "Arial"
                          },
                          "tlp": "Verified by: |verifiedBy|"
                        }
                      ]
                    }
                  ]

                  const replaceFooterData = (text) => {
                    return text.replace(/\|(\w+)\|/g, (_, key) => {
                      const value = footerData[key]
                      return value || ""
                    });
                  }

                  let footerContentClone = replaceFooterData(JSON.stringify(footerContent))
                  let footerContentValue = JSON.parse(footerContentClone);

                  for (let i = 0; i < footerContentValue.length; i++) {
                    const currEle = footerContentValue[i];
                    cloneLayout["sec"][0]["b"].push(currEle)
                  }

                  setTest((prev) => {
                    return { ...prev, [id]: { layout: JSON.stringify(JSON.stringify(layout)) } }
                  })
                }
              }
            }
          }
        }

      } catch (err) {
        console.log(err)
      }
    }

    if (report?.reportFormat.length > 0 && macro) {
      console.log("hii hero")
      fetchTest()
    } else {
      console.log("hii zero")
    }
  }, [report?._id, macro])

  const handleProcedureContentChange = (content) => {
    setValue(content)
  };

  function htmlToDelta(html) {
    return html
  }

  const saveLayout = async () => {
    try {
      const { data, status } = await api.updateLayout({
        investigationId: layoutId,
        patient: id,
        layout: value
      })

      if (status === 200) {
        toast.success("Saved Successfully")
        dispatch(setValues({ name: "reFetch", value: !reFetch }))
        handleClose()
      }
    } catch (err) {
      toast.error("Error Saving!")
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-scroll px-0"
    >
      <Box sx={style} >
        <div >
          <header className="flex justify-between  px-6 py-6 bg-[#FAFAFA]">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={macroclasses.name}
            >
              Report Layout
            </Typography>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </header>

          {
            Object.keys(test).map((id) => {
              const data = JSON.parse(test[id]?.layout ? JSON.parse(test[id]?.layout) : {})

              return <>
                <DocEditor
                  initialValue={data}
                  onChange={(value) => handleProcedureContentChange(value)}
                  setValue={setValue}
                  value={value}
                />
              </>
            })
          }

          <div
            style={{
              marginTop: '70px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              marginRight: '4%',
              marginBottom: "20px"
            }}>
            <Buttons className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Buttons>
            <Buttons
              className={classes.submitButton}
              onClick={saveLayout}
            >
              Save
            </Buttons>
          </div>
        </div>
      </Box>
    </Modal>
  );

}

export default ReportLayout;