import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tableStyles } from '../../Styles/AddNewDocStyle';
import Buttons from '../../Components/Shared/Buttons';
import { formStyles } from '../../Styles/Formstyle';
import { setValues } from '../../redux/findPatientSlice';
import { formatedDateInDigit } from '../../helper/helper';
import Barcode from 'react-barcode';
import Input from '../../Components/Shared/Input';
import { toast } from 'react-toastify';

const PrintBarCode = ({ id }) => {
    const tableclasses = tableStyles();
    const dispatch = useDispatch();
    const classes = formStyles();
    const { investigations } = useSelector(state => state.findPatient)
    const [noLabels, setNoLables] = useState(1);
    const [device, setDevice] = useState(localStorage.getItem("device") ? JSON.parse(localStorage.getItem("device")) : null);

    // get permission and print
    async function requestDevice(id) {
        try {
            if (device) {
                // Get a list of connected devices
                const devices = await navigator.usb.getDevices();
                const matchedDevice = devices.find(
                    (elm) => {
                        return elm.vendorId === device.vendorId && elm.productId === device.productId
                    }
                );

                testPrint(matchedDevice, id);
            } else {
                const getDevice = await navigator.usb.requestDevice({ filters: [] });

                // Save device info to localStorage
                const deviceInfo = { vendorId: getDevice.vendorId, productId: getDevice.productId };
                localStorage.setItem('device', JSON.stringify(deviceInfo));
                setDevice(deviceInfo);

                testPrint(getDevice, id);
            }

        } catch (e) {
            console.error(e);
        }
    }

    async function testPrint(device, id) {

        // left margin, top mark
        if (noLabels !== null) {
            const cmds = [
                'SIZE 48 mm,25 mm',
                'CLS',
                `TEXT 30,10,"2",0,1,1,"${`${investigations?.firstName}, ${investigations?.age}Y/${investigations?.gender.slice(0, 1).toUpperCase()}`}"`,
                `BARCODE 30,40,"128",50,1,0,3,2, "${id}"`,
                `TEXT 30,150,"2",0,1,1, "${`${formatedDateInDigit(investigations?.createdAt)}, ${investigations?.labPrefix?.prefix}- ${investigations?.labSuffix}`}"`,
                `PRINT ${noLabels}`,
                'END',
            ];

            await device.open();
            await device.selectConfiguration(1);
            await device.claimInterface(0);
            await device.transferOut(
                device.configuration.interfaces[0].alternate.endpoints.find(obj => obj.direction === 'out').endpointNumber,
                new Uint8Array(
                    new TextEncoder().encode(cmds.join('\r\n'))
                ),
            );
            await device.close();

            dispatch(setValues({ name: "barCode", value: false }))
        } else {
            console.log("User cancelled the prompt.");
        }
    }

    const handlePrintBarcode = () => {
        if (!id) {
            toast.error("Please select sample")
            return null
        }

        requestDevice(id)
    }

    const detachPrinter = () => {
        setDevice(null)
        localStorage.removeItem("device")
        toast.success("Printer removed successfully")
    }

    return (
        <div className={tableclasses.outBackground}>
            <div className={tableclasses.sampleCollection} style={{ marginTop: '128px' }}>
                <div
                    className={tableclasses.exitbutton}
                    style={{ cursor: "pointer" }}
                    onClick={() => dispatch(setValues({ name: "barCode", value: false }))}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{ position: 'relative', top: '23px', left: '92.5%' }}
                    >
                        <path
                            d="M1 17L17 1M1 1L17 17"
                            stroke="black"
                            stroke-opacity="0.7"
                            stroke-width="1.88067"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div className={classes.biodata} style={{ padding: 20 }}>
                    <div style={{ display: "flex" }}>
                        <div className={classes.question}>
                            <p>Lab</p>
                            <p>Patient name</p>
                            <p>Age/Sex</p>
                            <p>Sample from</p>
                            <p>Created date</p>
                            <p>BarCode</p>
                            <p style={{ marginTop: 25 }}>No of Labels</p>
                        </div>
                        <div className={classes.of}>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p>:</p>
                            <p style={{ marginTop: 25 }}>:</p>
                        </div>
                        <div className={classes.question}>
                            <p>{investigations?.labPrefix?.prefix ? investigations?.labPrefix?.prefix : "NA"}</p>
                            <p>
                                {investigations?.firstName} {investigations?.lastName}
                            </p>
                            <p>
                                {investigations?.age}yrs / {investigations?.gender}
                            </p>
                            <p>{investigations?.sampleFrom ? investigations?.sampleFrom : "NA"}</p>
                            <p>{formatedDateInDigit(investigations?.createdAt)}</p>
                            <p> <Barcode displayValue={false} width={1} height={30} fontSize={14} value={id} margin={0} background='transparent' /> </p>
                            <Input
                                type="number"
                                placeholder="Enter Labels"
                                className={classes.formInput}
                                name="labels"
                                value={noLabels}
                                onChange={(e) => setNoLables(e.target.value)}
                                style={{ width: "100px" }}
                            // onBlur={handleBlur}
                            />
                        </div>
                    </div>

                </div>

                <div className={tableclasses.sampleIn}>
                    <Buttons
                        className={classes.cancelButton}
                        onClick={() => dispatch(setValues({ name: "barCode", value: false }))}
                    >
                        Cancel
                    </Buttons>
                    <span className="px-1"></span>
                    <Buttons
                        className={classes.submitButton}
                        onClick={detachPrinter}
                    >
                        Detach Printer
                    </Buttons>
                    <span className="px-1"></span>
                    <Buttons
                        className={classes.submitButton}
                        onClick={handlePrintBarcode}
                    >
                        Print
                    </Buttons>
                </div>
            </div>

        </div>
    )
}

export default PrintBarCode