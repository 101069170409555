import React, { useEffect, useMemo, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { macroStyles } from "../../Styles/Macro";
import { formStyles } from "../../Styles/Formstyle";
import Buttons from "./Buttons";
import { toast } from "react-toastify";
import Api from "../../helper/api";
import { formatTime, formatedDate, formatedDateInDigit } from "./../../helper/helper";
import CustomImage from "./CustomImage";
import { useDispatch, useSelector } from "react-redux";
import { setValues } from "../../redux/findPatientSlice";
import AddTest from "./AddTest";
import AddMacro from "./AddMacro";
import GetMacro from "./GetMacro";
import DocEditor from "./Editor";

const copyStringToClipboard = function (str) {
    var el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};

const facilityMergeFields = [
    "FacilityNumber",
    "FacilityName",
    "Address",
    "MapCategory",
    "Latitude",
    "Longitude",
    "ReceivingPlant",
    "TrunkLine",
    "SiteElevation"
];
const inspectionMergeFields = [
    "InspectionCompleteDate",
    "InspectionEventType"
];

const createOptionGroupElement = (mergeFields, optionGrouplabel) => {
    let optionGroupElement = document.createElement("optgroup");
    optionGroupElement.setAttribute("label", optionGrouplabel);
    for (let index = 0; index < mergeFields.length; index++) {
        let optionElement = document.createElement("option");
        optionElement.setAttribute("class", "merge-field-select-option");
        optionElement.setAttribute("value", mergeFields[index]);
        optionElement.text = mergeFields[index];
        optionGroupElement.appendChild(optionElement);
    }
    return optionGroupElement;
}

const buttons = [
    "undo",
    "redo",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "superscript",
    "subscript",
    "|",
    "align",
    "|",
    "ul",
    "ol",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "link",
    "table",
    "|",
    "hr",
    "eraser",
    "copyformat",
    "|",
    "fullsize",
    "selectall",
    "print",
    "|",
    "source",
    "|",
    {
        name: "insertMergeField",
        tooltip: "Insert Merge Field",
        iconURL: "images/merge.png",
        popup: (editor, current, self, close) => {
            function onSelected(e) {
                let mergeField = e.target.value;
                if (mergeField) {
                    console.log(mergeField);
                    editor.selection.insertNode(
                        editor.create.inside.fromHTML("{{" + mergeField + "}}")
                    );
                }
            }
            let divElement = editor.create.div("merge-field-popup");

            let labelElement = document.createElement("label");
            labelElement.setAttribute("class", "merge-field-label");
            labelElement.text = 'Merge field: ';
            divElement.appendChild(labelElement);

            let selectElement = document.createElement("select");
            selectElement.setAttribute("class", "merge-field-select");
            selectElement.appendChild(createOptionGroupElement(facilityMergeFields, "Facility"));
            selectElement.appendChild(createOptionGroupElement(inspectionMergeFields, "Inspection"));
            selectElement.onchange = onSelected;
            divElement.appendChild(selectElement);

            console.log(divElement);
            return divElement;
        }
    },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    height: "100%",
    width: "900.88px",
    padding: "0 0px",
    // overflow: "scroll",
    display: "block",

    borderRadius: "6px",
    "&:focus": {
        outline: "none",
    },
};

const Layout = ({ handleClose, layoutId, id }) => {
    const api = new Api();
    const classes = formStyles();
    const macroclasses = macroStyles();
    const dispatch = useDispatch()
    const { reFetch } = useSelector(state => state.findPatient)

    const [open, setOpen] = React.useState(true);
    const macros = useRef(null)
    const [value, setValue] = useState(null);
    const [initialValue, setInitialValue] = useState(null);
    const token = localStorage.getItem("logintoken")

    const editorConfig = {
        uploader: {
            insertImageAsBase64URI: false,
            imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
            withCredentials: false,
            format: 'json',
            method: 'POST',
            url: 'https://backend.nagalikardiagnostic.com/api/upload-file',
            headers: {
                "authtoken": token
            },
            prepareData: function (data) {
                return data;
            },
            isSuccess: function (resp) {
                return !resp.error;
            },
            getMsg: function (resp) {
                return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
            },
            process: function (resp) {
                console.log(resp[0].src)
                return {
                    files: [resp[0].src],
                    path: '',
                    baseurl: '',
                    error: resp.error ? 1 : 0,
                    msg: resp.msg
                };
            },
            defaultHandlerSuccess: function (data, resp) {
                const files = data.files || [];
                if (files.length) {
                    this.selection.insertImage(files[0], null, 250);
                }
            },
            defaultHandlerError: function (resp) {
                this.events.fire('errorPopap', this.i18n(resp.msg));
            }
        },
    };


    const fetchData = async () => {
        const { data, status } = await api.fetchReportById({}, { id: id })

        if (status === 200) {
            if (data.report?.layout) {
                setValue(JSON.parse(data.report.layout))
                setInitialValue(JSON.parse(data.report.layout))
            }
        }

    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleProcedureContentChange = (content) => {
        setValue(content)
    };


    const saveLayout = async () => {
        try {
            const { data, status } = await api.putReq(`/editreportformat/${id}`,
                {
                    layout: JSON.stringify(value)
                })

            if (status === 200) {
                toast.success("Saved Successfully")
                dispatch(setValues({ name: "reFetch", value: !reFetch }))
                handleClose()
            }
        } catch (err) {
            toast.error("Error Saving!")
        }
    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="overflow-y-scroll px-0"
        >
            <Box sx={style} >
                <div >
                    <header className="flex justify-between  px-1 py-1 bg-[#FAFAFA]">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            className={macroclasses.name}
                        >
                            Report Layout
                        </Typography>
                        <button onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </header>
                    <DocEditor
                        initialValue={initialValue}
                        setValue={setValue}
                        value={value}
                        onChange={(value) => handleProcedureContentChange(value)}
                    />

                    <div
                        style={{
                            marginTop: '0px',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'right',
                            marginRight: '4%',
                            marginBottom: "20px"
                        }}>
                        <Buttons className={classes.cancelButton}
                            onClick={handleClose}
                        >
                            Cancel
                        </Buttons>
                        <Buttons
                            className={classes.submitButton}
                            onClick={saveLayout}
                        >
                            Save
                        </Buttons>
                    </div>
                </div>
            </Box>
        </Modal>
    );

}

export default Layout;