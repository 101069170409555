import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../helper/api";
import { toast } from "react-toastify";

const api = new Api();

const initialState = {
    search: "",
    assign: false,
    id: null,
    filterData: {
        status: "",
        date: new Date(),
        category: []
    },
    isDeleting: false,
    account: [],
    activeAccount: "",
    activePatient: null,
    activeReceipt: null,
    activeReport: null,
    activeSample: null,
    activeSampleNo: null,
    deletePatient: false,
    deleteInvestigation: false,
    deleteReport: false,
    deleteReceipt: false,
    assign: false,
    addInvestPopup: false,
    addReceipt: false,
    assignSample: false,
    editSample: false,
    editReceipt: false,
    image: false,
    showReport: {
        show: false,
        src: ""
    },
    rows: [],
    page: 1,
    reFetch: false,
    rowsPerPage: 10,
    pageInfo: {},
    newData: false,
    loading: false,
    layout: false,
    macro: false,
    investigations: {
        investigation: [],
        payment: {
            deposits: []
        },
        sampleCollection: []
    },
    barCode: false,
    activeInvestigation: null,
    payment: {
        totalAmount: 0,
        netAmount: 0,
        totalDiscount: 0,
        totalPaidAmount: 0,
        totalDueAmount: 0
    }
}

export const fetchPatient = createAsyncThunk("fetchPatient", async (body, { rejectWithValue, dispatch, getState }) => {
    try {
        const { filterData, page, search, activeAccount } = getState().findPatient;

        const { date, status, category } = filterData
        const response = await api.getPatientList("", {
            page, date: [date, date], status, category, search, account: activeAccount
        })

        dispatch(setRows(response.data.patients));
        dispatch(setPageInfo(response.data));
    } catch (err) {
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const fetchData = createAsyncThunk("fetchData", async (body, { rejectWithValue, getState, dispatch }) => {
    try {
        const { activePatient } = getState().findPatient;

        const { data, status } = await api.fetchPatientById("", { id: activePatient })

        if (status === 200) {
            dispatch(setInvestigations(data))
            if (data.investigation[0]?._id) {
                dispatch(setValues({ name: "activeInvestigation", value: data.investigation[0]._id }))
                dispatch(setValues({ name: "activeReport", value: data.investigation[0]._id }))
            }

            if (data.payment.deposits[0]?.paymentNo) {
                dispatch(setValues({ name: "activeReceipt", value: data.payment.deposits[0]?.paymentNo }))
            }
            const payment = data?.payment;
            let totalAmount = 0;
            let totalDiscount = 0;
            let netAmount = 0;
            let totalPaidAmount = 0;

            for (let i = 0; i < payment.bills.length; i++) {
                totalAmount += payment.bills[i].totalAmount;
                totalDiscount += payment.bills[i].discount;
                netAmount += payment.bills[i].netAmount;
            }

            for (let i = 0; i < payment.deposits.length; i++) {
                totalPaidAmount += payment.deposits[i].paidAmount;
            }

            dispatch(setPayment({
                totalAmount,
                totalDiscount,
                netAmount,
                totalPaidAmount,
                totalDueAmount: netAmount - totalPaidAmount,
                payment
            }));
        }
    } catch (err) {
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const deletePatientAPI = createAsyncThunk("fetchData", async (body, { rejectWithValue, getState, dispatch }) => {
    try {
        dispatch(setValues({ name: "isDeleting", value: true }))
        const { activePatient, newData, rows } = getState().findPatient;

        const { data, status } = await api.deletePatient("", { id: activePatient })

        if (status === 200) {
            dispatch(setValues({ name: "isDeleting", value: false }))
            dispatch(setValues({ name: "deletePatient", value: false }))
            dispatch(setValues({ name: "newData", value: !newData }))

            const findIndex = rows.findIndex(row => row._id === activePatient)
            if (rows[findIndex - 1]?._id) {
                const _id = rows[findIndex - 1]?._id
                dispatch(setActivePatient(_id));
            } else if (rows[findIndex + 1]?._id) {
                const _id = rows[findIndex + 1]?._id
                dispatch(setActivePatient(_id));
            }
        }
    } catch (err) {
        dispatch(setValues({ name: "isDeleting", value: false }))
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const deleteInvestigationAPI = createAsyncThunk("fetchData", async (body, { rejectWithValue, getState, dispatch }) => {
    try {
        dispatch(setValues({ name: "isDeleting", value: true }))
        const { activeInvestigation, activePatient, reFetch, investigations } = getState().findPatient;

        const { data, status } = await api.deleteInvestigation("", { id: activePatient, investigation: activeInvestigation })

        if (status === 200) {
            dispatch(setValues({ name: "isDeleting", value: false }))
            dispatch(setValues({ name: "deleteInvestigation", value: false }))
            dispatch(setValues({ name: "reFetch", value: !reFetch }))

            const findIndex = investigations.investigation.findIndex(row => row._id === activeInvestigation)
            if (investigations.investigation[findIndex - 1]?._id) {
                const _id = investigations.investigation[findIndex - 1]?._id
                dispatch(setValues({ name: "activeInvestigation", value: _id }));
            } else if (investigations.investigation[findIndex + 1]?._id) {
                const _id = investigations.investigation[findIndex + 1]?._id
                dispatch(setValues({ name: "activeInvestigation", value: _id }));
            }
        }
    } catch (err) {
        dispatch(setValues({ name: "isDeleting", value: false }))
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const fetchAccount = createAsyncThunk("fetchAccount", async (body, { rejectWithValue, dispatch, getState }) => {
    try {
        const { data, status } = await api.fetchAccount();

        if (status === 200) {
            dispatch(setValues({ name: "account", value: data?.accountDetails }))
        }

    } catch (err) {
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const deletePatientReceipt = createAsyncThunk("deleteReceipt", async (body, { rejectWithValue, getState, dispatch }) => {
    try {
        dispatch(setValues({ name: "isDeleting", value: true }))
        const { activePatient, activeReceipt, reFetch } = getState().findPatient;

        const { data, status } = await api.deletePatientPayment("", { id: activePatient, paymentNo: activeReceipt })

        if (status === 200) {
            dispatch(setValues({ name: "isDeleting", value: false }))
            dispatch(setValues({ name: "deleteReceipt", value: false }))
            dispatch(setValues({ name: "reFetch", value: !reFetch }))
        }
    } catch (err) {
        dispatch(setValues({ name: "isDeleting", value: false }))
        return rejectWithValue(err.response.data.message || "Something went wrong. Please try again later.")
    }
})

export const sendRepoByEmail = createAsyncThunk("sendReport", async (body, { rejectWithValue, getState, dispatch }) => {
    try {
        const { activePatient, activeReport, reFetch } = getState().findPatient;

        dispatch(setValues({ name: "loading", value: true }))
        const { data, status } = await api.sendRepobyEmail({
            id: activePatient,
            reportId: activeReport
        })

        if (status === 200) {
            toast.success("Report emailed successfully")
            dispatch(setValues({ name: "reFetch", value: !reFetch }))
            dispatch(setValues({ name: "loading", value: false }))
        }
    } catch (err) {
        toast.error(err.response.data.message)
        dispatch(setValues({ name: "loading", value: false }))
    }
})

const findPatientSlice = createSlice({
    name: "findPatient",
    initialState,
    reducers: {
        setFilterData: (state, action) => {
            state.filterData[action.payload.name] = action.payload.value
        },
        setRows: (state, action) => {
            state.rows = action.payload
        },
        setPageInfo: (state, action) => {
            state.pageInfo = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setNewData: (state, action) => {
            state.newData = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setActivePatient: (state, action) => {
            state.activePatient = action.payload.patient
            if (action.payload.sampleNo) {
                state.activeSampleNo = action.payload.sampleNo
            }
        },
        setValues: (state, action) => {
            state[action.payload.name] = action.payload.value
        },
        setPayment: (state, action) => {
            state.payment = action.payload
        },
        setInvestigations: (state, action) => {
            state.investigations = action.payload
        }
    },
    extraReducers: (builder) => {
        // login
        // builder.addCase(fetchPatient.pending, (state) => {
        //     state.isLoggedIn = false
        //     state.isError = false
        // })
        // builder.addCase(fetchPatient.fulfilled, (state, action) => {
        //     state.isLoggedIn = true
        //     state.user = action.payload
        // })
        // builder.addCase(fetchPatient.rejected, (state, action) => {
        //     state.isLoggedIn = false
        //     state.errorMsg = action.payload
        // })
    }
})

export const { setFilterData, setValues, setActivePatient, setPayment, setInvestigations, setPage, setNewData, setSearch, setRows, setPageInfo } = findPatientSlice.actions

export default findPatientSlice.reducer