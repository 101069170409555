import React, { useState, useEffect } from "react";
import Input from "../Components/Shared/Input";
import { useStyles } from "../Styles/InputStyle";
import axios from "axios";
import { API } from "../config";
import { useNavigate } from "react-router-dom";
import Buttons from "../Components/Shared/Buttons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';

const Login = () => {
  const classes = useStyles();

  const [data, setData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("user");
    const role = localStorage.getItem("role");

    if (user && role) {
      role === "user" ? navigate('/list-patience') : navigate("/register-doctor");
      window.location.reload();
    }
  }, [])

  const handleLogin = async ({ email, password }) => {
    try {
      //1. fetch data
      const { data, status } = await axios.post(`${API}/login`, {
        email: email,
        password: password,
      });

      if (status === 200) {

        //2. save the token
        localStorage.setItem("logintoken", data?.token);

        // 3. save the user info
        if (data?.admin?.role === "admin" || data?.user?.role === "admin") {
          localStorage.setItem("user", data?.admin?.email || data?.user?.email);
          localStorage.setItem("role", data?.admin?.role || data?.user?.role);
          setTimeout(() => {
            navigate("/register-doctor");
            window.location.reload();
          }, 1500)
        } else {
          localStorage.setItem("user", data?.user?.email);
          localStorage.setItem("role", data?.user?.role);
          setTimeout(() => {
            navigate("/list-patient");
            window.location.reload();
          }, 1500)
        }

        toast.success("Login Successful");
      }
    }
    catch (error) {
      toast.error(error.response.data.error || "Oops, Something went wrong");
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Initialize useFormik hook
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  // Extract formik props
  const { values, errors, touched, handleChange, handleSubmit } = formik;

  return (
    <>
      {/*  <ThemeProvider theme={theme}> */}
      <div className={classes.myDiv}>
        <div className={classes.myDiv1}>
          <p className={classes.textLogo}>Nagalikar</p>
        </div>
        <div className={classes.myDiv2}>
          <div>
            <p className={classes.maintext1}>
              Lorem Ipsum is <br />
              simply <span className={classes.subtext1}>dummy text</span>{" "}
            </p>
            <br />
            <p className={classes.subtitle1}>
              Lorem Ipsum is simply dummy text of the <br />
              printing and typesetting industry.{" "}
            </p>
          </div>
          <div>
            <img src="/images/login-img.png" alt="" />
          </div>
          <div className={classes.myDiv3}>
            <div className={classes.myDiv4}>
              <div>
                <p className={classes.maintext2}>Log In Here</p>
                <p className={classes.subtitle2}>
                  Manage all of Patients reports and view <br />
                  referred doctor details
                </p>
              </div>

              <form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  type="email"
                  placeholder="Mobile / Email ID"
                  value={values.email}
                  onChange={handleChange}
                  className={classes.customInput}
                  error={errors.email && touched.email}
                />
                {/* {errors.email && touched.email && (
                  <div style={{color:"red"}}>{errors.email}</div>
                )} */}
                <br />
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  className={classes.customInput}
                  error={errors.password && touched.password}
                />
                {/* {errors.password && touched.password && (
                  <div style={{color:"red"}}>{errors.password}</div>
                )} */}
                <br /> <br />
                {/* <span className={classes.caption}>Forget Password?</span>{" "}
                <br /> <br /> */}
                <Buttons type="submit" className={classes.customButton}>
                  Login
                </Buttons>
              </form>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Login;
