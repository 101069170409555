import React from 'react'
import { DocumentEditorComponent, Print, SfdtExport, WordExport, TextExport, Selection, Search, Editor, ImageResizer, EditorHistory, ContextMenu, OptionsPane, HyperlinkDialog, TableDialog, BookmarkDialog, TableOfContentsDialog, PageSetupDialog, StyleDialog, ListDialog, ParagraphDialog, BulletsAndNumberingDialog, FontDialog, TablePropertiesDialog, BordersAndShadingDialog, TableOptionsDialog, CellOptionsDialog, StylesDialog } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useRef, useState } from 'react';

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import "@syncfusion/ej2-documenteditor/styles/material.css";

DocumentEditorComponent.Inject(Print, SfdtExport, WordExport, TextExport, Selection, Search, Editor, ImageResizer, EditorHistory, ContextMenu, OptionsPane, HyperlinkDialog, TableDialog, BookmarkDialog, TableOfContentsDialog, PageSetupDialog, StyleDialog, ListDialog, ParagraphDialog, BulletsAndNumberingDialog, FontDialog, TablePropertiesDialog, BordersAndShadingDialog, TableOptionsDialog, CellOptionsDialog, StylesDialog);


const DocViewer = ({ initialValue, documentRef }) => {
  const [editorHeight, setEditorHeight] = useState('600px');

  useEffect(() => {
    if (documentRef.current && initialValue) {
      // Open the serialized content
      documentRef.current.open(initialValue);

      const sectionProperties = documentRef.current.selection.sectionFormat;
      sectionProperties.topMargin = 160; // 72 points = 1 inch
    }
  }, [initialValue])



  return (
    <div className="docEditorx">
      <DocumentEditorComponent
        id="container"
        ref={documentRef}
        height={700}
        // layoutType='Continuous'
        serviceUrl="https://services.syncfusion.com/vue/production/api/documenteditor/"
        isReadOnly={false}
        enablePrint={true}
        enableEditor={true}
        enableSelection={true}
        documentEditorSettings={{
          printDevicePixelRatio: 4
        }}
        enableWordExport={true} />
    </div>
  )
}

export default DocViewer