import React, { useEffect, useState } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, Button, InputLabel, List, ListItemText, Tab } from '@material-ui/core';
import Buttons from "../../Components/Shared/Buttons";
import { Checkbox, Select } from "@material-ui/core";
import Api from '../../helper/api';
import Selects, { components, MenuListProps } from 'react-select';
import { toast } from 'react-toastify'

const GetMacro = ({ setTest, insertMacro }) => {
    const tableclasses = tableStyles();
    const classes = formStyles();
    const [priceList, setPriceList] = useState([]);
    const [investigation, setInvestigation] = useState([]);
    const api = new Api();

    const handleInvestigation = (value) => {
        setInvestigation(value);
        const macro = priceList.find((item) => item._id === value?.value)

        if (macro?.macro) {
            insertMacro(
                macro.macro
            )
        }
        setTest(false)
    }

    const fetchMacro = async () => {
        try {
            const { data, status } = await api.macro().get(`/macro?group`)

            if (status === 200) {
                setPriceList(data);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        fetchMacro();
    }, [])

    return (
        <div className={tableclasses.outBackground}>
            <div className={tableclasses.sampleCollection} style={{ marginTop: '48px', paddingBottom: "100px", width: '55%' }}>
                <div
                    className={tableclasses.exitbutton}
                    style={{ cursor: "pointer" }}
                    onClick={() => setTest(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{ position: 'relative', top: '23px', left: '92.5%' }}
                    >
                        <path
                            d="M1 17L17 1M1 1L17 17"
                            stroke="black"
                            stroke-opacity="0.7"
                            stroke-width="1.88067"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div className={tableclasses.headSample}>Add macro</div>
                <div className={tableclasses.accessHeader} style={{ flexDirection: 'column', borderBottom: '1px #9c9c9c solid', marginBottom: '20px', paddingBottom: '25px' }}>
                    <div className={classes.formLable}
                        style={{ width: '700px' }}
                    >Test Information</div>
                    <Selects
                        options={[{
                            options: priceList?.map((price) => {
                                return {
                                    value: price?._id,
                                    label: price?.code,
                                }
                            })
                        }]}
                        value={{
                            value: investigation?._id,
                            label: investigation?.name,
                        }}
                        // components={{ Option, GroupHeading }}
                        styles={{ container: (styles) => ({ ...styles, width: "90%", marginTop: '11px', marginBottom: "28px" }), control: (styles) => ({ ...styles, padding: "3px 0" }) }}
                        onChange={(value) => handleInvestigation(value)}
                    />
                </div>
            </div>
        </div >
    )
}

export default GetMacro
