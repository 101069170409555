import React, { useState, useEffect } from 'react'
import { tableStyles } from "../../Styles/AddNewDocStyle";
import { formStyles } from "../../Styles/Formstyle";
import {
  TableCell
} from "@material-ui/core";
import { useNavigate, useParams } from 'react-router-dom';
import Buttons from "../../Components/Shared/Buttons";
import Api from '../../helper/api';
import { formatedDateInDigit } from '../../helper/helper';

const ViewPatienceCard = () => {
  const api = new Api();
  const params = useParams();
  const id = params.id;
  const tableclasses = tableStyles();
  const classes = formStyles();
  const [rows, setRows] = useState();
  const navigate = useNavigate();
  const fetchPatient = async () => {
    try {
      const { data, status } = await api.fetchPatientCardById({}, { id });
      setRows(data.singlecard)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchPatient();
  }, [])
  return (
    <div className={tableclasses.root} style={{ margin: "25px" }}>
      <div className={tableclasses.appointmentbody}>
        <div className={tableclasses.headertwo}>
          <div className={classes.formname}>
            <div className={classes.formh2}>{rows?.firstName} {rows?.lastName}</div>
            <div className={classes.viewHead}>
              <div className={`${classes.formspecification}`}
                style={{ marginLeft: '50px', marginRight: '50px' }}
              >
                Patient ID: {rows?._id}
              </div>
              <div className={classes.formspecification} style={{ display: 'flex', alignItems: 'center' }}>
                Status:
                <TableCell className={tableclasses.customTableCell}>
                  <Buttons className={tableclasses.customActive}>
                    <div style={{ color: 'green' }}>
                      Active</div>
                  </Buttons>
                </TableCell>
              </div>
              <div
                className={classes.formspecification}
                style={{ color: "#fff" }}
              >
                Urgent Requirement
              </div>
            </div>
            <Buttons
              className={classes.backToButton}
              style={{ width: "120px" }}
              onClick={() => navigate("/patience-cards")}
            >
              Back to Table
            </Buttons>
          </div>
        </div>
        <div className={classes.biodata}>
          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Lab</p>
              <p>Patient name</p>
              <p>Age/Sex</p>
              <p>Mobile number</p>
              <p>Email address</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{rows?.labPrefix?.prefix ? rows?.labPrefix?.prefix : "NA"}</p>
              <p>
                {rows?.firstName} {rows?.lastName}
              </p>
              <p>
                {rows?.age}yrs / {rows?.gender}
              </p>
              <p>{rows?.phoneNumber ? rows?.phoneNumber : "NA"}</p>
              <p>{rows?.emailAddress ? rows?.emailAddress : "NA"}</p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className={classes.question}>
              <p>Referred By</p>
              <p>City</p>
              <p>Address</p>
              <p>Date</p>
            </div>
            <div className={classes.of}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div className={classes.question}>
              <p>{rows?.referredBy[0]?.name ? rows?.referredBy[0]?.name : "Self"}</p>
              <p>{rows?.city ? rows?.city : "NA"}</p>
              <p>{rows?.address ? rows?.address : "NA"}</p>
              <p>{formatedDateInDigit(rows?.createdAt)}</p>
            </div>
          </div>
          <div className={classes.imageside}></div>
        </div>
      </div>
    </div>
  )
}

export default ViewPatienceCard
